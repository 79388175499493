import { useContext, useEffect, useState } from 'react';
import { EstablishmentContext } from './EstablishmentContext';
import { useTable } from '../../hooks/useTable';
import { AppContext } from '../global/AppContext';
import { Roles } from '../../constants/Actions';
import { getEstablishmentsByFilter } from '../../services/establishmentServices';

export const EstablishmentState = ({ children }) => {
  const [filter, setFilter] = useState('');
  const tableInfo = useTable(true);
  const paginationProps = tableInfo.paginationProps;
  const { user } = useContext(AppContext);

  useEffect(() => {
    if (user) {
      initTable();
    }
  }, [user]);

  const initTable = async () => {
    if (user.roles.includes(Roles.SUPERADMIN)) {
      updateInfoSuperAdmin(10, 0, '');
    } else {
      updateInfoEstablishment(10, 0, '');
    }
  };

  const updateInfoEstablishment = (limit, page = paginationProps.page, name = filter) => {
    let newLimit = !limit ? paginationProps.limit : limit;
    const filterData = user.establishments.filter((x) => (name ? x.name.toLowerCase().includes(name.toLowerCase()) : x));
    const length = filterData.length;
    const paginateData = filterData.slice(page * newLimit, page * newLimit + newLimit);
    tableInfo.onChange(paginateData, newLimit, page, length);
  };

  const updateInfoSuperAdmin = async (limit = paginationProps.limit, page = paginationProps.page, name = filter) => {
    const res = await getEstablishmentsByFilter(name, limit, page + 1);
    tableInfo.onChange(
      res.items.map((x) => {
        return {
          id: x.id,
          name: x.establishment_name,
          is_public: x.is_public,
          address: x.address,
          management: x.is_public ? 'Estatal' : 'Privada',
          level: x.level?.charAt(0).toUpperCase() + x?.level?.slice(1).toLowerCase(),
          is_terciary: x.isEnableNewCareer
        };
      }),
      limit,
      page,
      res.meta.totalItems
    );
    setFilter(name);
  };

  return (
    <EstablishmentContext.Provider
      value={{
        establishments: tableInfo.data || [],
        paginationProps: tableInfo.paginationProps,
        updateInfoSuperAdmin,
        updateInfoEstablishment
      }}>
      {children}
    </EstablishmentContext.Provider>
  );
};
