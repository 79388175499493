import { Box, Grid, Paper, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomStepper } from '../../../components/CustomStepper';
import { CustomSteps } from '../../../components/CustomSteps';
import { CustomYellowButton } from '../../../components/CustomYellowButton';
import { GenericForm } from '../../../components/GenericForm';
import { GreyButton } from '../../../components/GreyButton';
import { ObservationDetailAlert } from '../../../components/ObservationDetailAlert';
import curriculumStepOneConfig from '../../../configs/curriculumStepOneConfig.json';
import { CurriculumStates } from '../../../constants/CurriculumStates';
import { CurriculumStepOneContext } from '../../../context/Curriculum/CurriculumContext';
import { useGenericForm } from '../../../hooks/useGenericForm';
import { StudyPlanRouteConstants } from '../../../routes/RoutesConstants';
import { useAuthHelper } from '../../../hooks/useAuthHelper';
import { Actions } from '../../../constants/Actions';

let stepsArray = ['Información general', 'Caja curricular'];

const { formControls, conduccionFormControls } = curriculumStepOneConfig;
export const CurriculumForm = () => {
  const {
    options,
    formData,
    getCurriculumByIdData,
    createStudyPlanByAmendingResolution,
    patchCurriculumModified,
    setIsNewStudyPlan,
    isNewStudyPlan
  } = useContext(CurriculumStepOneContext);
  const mapFormControls = () =>
    formControls.map((e) => {
      if (e.field === 'id') {
        e.action = handleChangeStudyPlan;
      }
      return e;
    });
  const {
    form,
    config,
    initForm,
    handleValue,
    setConfig,
    getEmptyForm,
    validateFormAndExecutePostAction,
    hasEmptyRequiredFields,
    hasModifications,
    modifyConfig
  } = useGenericForm(formControls, mapFormControls);
  const { isAllowed, isUserConduccion } = useAuthHelper();

  const [disableForm, setDisableForm] = useState(false);
  const [activateNewStudyPlan, setActivateNewStudyPlan] = useState(false);
  const [editLoad, setEditLoad] = useState(false);

  const navigate = useNavigate();
  const params = useParams();
  const [studyPlanId, setStudyPlanId] = useState(params.id || null);
  const [originalStudyPlanId, setOriginalStudyPlanId] = useState(null);

  const isUserAdminOrLegalization = isAllowed([Actions.READ_GLOBAL_STUDYPLAN]);
  const cancelRedirectLink = isUserAdminOrLegalization
    ? StudyPlanRouteConstants.BUSCAR_POR_ESTABLECIMIENTO(localStorage.getItem('establishmentId'), localStorage.getItem('establishmentName'))
    : StudyPlanRouteConstants.BUSCAR;
  const [isStudyPlanInAprovedArray, setIsStudyPlanInAprovedArray] = useState();

  useEffect(() => {
    if (disableForm) {
      blockFormControls();
      setDisableForm(false);
    }
  }, [disableForm]);

  useEffect(() => {
    if (activateNewStudyPlan) {
      enableNewStudyPlan();
      setActivateNewStudyPlan(false);
    }
  }, [activateNewStudyPlan]);

  //esto lo hago porque necesito que precargue el config antes de inicializarlo
  useEffect(() => {
    if (editLoad) {
      initForm(formData, options);
    }
  }, [editLoad]);

  useEffect(() => {
    if (formData && options) {
      if (params.id && !editLoad) {
        setIsStudyPlanInAprovedArray(options.curriculums.find((curriculum) => curriculum.name === formData.study_plan_name));
        setPreLoadControls(
          formData.study_plan_status_id === CurriculumStates.NUEVO.id || formData.study_plan_status_id === CurriculumStates.OBSERVADO.id
        );
        setEditLoad(true);
      } else {
        initForm(formData, options);
      }
      //si viene id bloqueo formulario
    }
  }, [formData, options]);

  useEffect(() => {
    // Modifica la configuración si el user tiene rol CONDUCCIÓN_FIRMA ó CONDUCCIÓN:
    if (isUserConduccion()) modifyConfig(formControls, [...conduccionFormControls]);
  }, []);

  const nextHandleClick = async (formatedForm) => {
    let id;
    const isNew = formatedForm.study_plan_status_id === CurriculumStates.NUEVO.id;
    const isObserved = formatedForm.study_plan_status_id === CurriculumStates.OBSERVADO.id;
    const hasAmendingResolution = formatedForm.amending_resolution;
    /**
     * Se guarda en almacenamiento local el `studyPlanId` SOLAMENTE si no existe esa variable,
     * porque de lo contrario la modifica cada vez que ingresamos al paso 1.
     * LA RAZÓN para guardar este valor (por lo que dure la nevegación para asociar un plan de
     * estudios) es que si se crea un plan con RM y se avanza al paso 2 y el user quiere VOLVER
     * al paso 1 y eliminar la RM para que el plan a asociar sea EL DE BASE es necesario saber
     * cuál era el ID de este PLAN BASE, el cual la app olvida en cuanto se pasa al paso 2, ya
     * que toma el ID del nuevo plan con RM.
     */
    !localStorage.getItem('baseStudyPlan') && localStorage.setItem('baseStudyPlan', studyPlanId);
    /**
     * Se settea el objeto a enviar a API para que contemple el ID del plan original en que se basa
     * y tenerlo disponible para los cálculos donde se lo precise.
     */
    if (!formatedForm.originalStudyPlanId && originalStudyPlanId) {
      formatedForm.originalStudyPlanId = originalStudyPlanId || localStorage.getItem('baseStudyPlan');
    }

    // CASO: si no existe lo creo en la base
    if ((!formatedForm.id || (formatedForm.id && hasAmendingResolution)) && !params.id) {
      id = await createStudyPlanByAmendingResolution(formatedForm);
    } else {
      // CASO: si existe y es nuevo sin resolucion, hago un patch del paso 1
      if (params.id && (isNew || isObserved)) {
        if (formatedForm.amending_resolution === '') {
          if (isNew) {
            // CASO: Todavía no se asoció el plan (pero está creado en la BD) y se quiere eliminar la
            // RM para que tome el plan de base
            await patchCurriculumModified(formatedForm, isNew || isObserved);
            const baseStudyPlanId = JSON.parse(localStorage.getItem('baseStudyPlan'));
            id = baseStudyPlanId;
          } else if (isObserved) {
            if (formatedForm.originalStudyPlanId) {
              // CASO: El plan se creó con uno precargado y se le elimina la RM:
              localStorage.setItem('baseStudyPlan', formatedForm.originalStudyPlanId); // Se guarda ID original durante la navegación por si el user la necesita
              id = formatedForm.originalStudyPlanId;
            } else {
              // CASO: El plan no es precargado (es creado desde cero) y se elimina la RM:
              await patchCurriculumModified(formatedForm, isNew || isObserved);
              id = params.id;
            }
          }
        } else {
          await patchCurriculumModified(formatedForm, isNew || isObserved);
          id = params.id;
        }
      } else if (!isNew && !isObserved && hasAmendingResolution) {
        if (formatedForm.amending_resolution === '') {
          // CASO: Tenía Resolución Modificatoria y se la eliminó
          formatedForm.amending_resolution = null;
          await patchCurriculumModified(formatedForm, isNew || isObserved);
          id = params.id;
        } else {
          // CASO: No tenía Resolución Modificatoria y se le agregó:
          /**
           * Se guarda en el objeto a enviar a API la ID del plan en que se basa
           */
          formatedForm.originalStudyPlanId = JSON.parse(localStorage.getItem('baseStudyPlan'));
          /**
           * La condición que determina el valor de esta variable calcula si el plan al que se le está queriendo
           * hacer una modificación es **creado desde cero** y **aprobado**;
           * - si éste es el caso, debe mandar un parámetro extra que fuerza a `createStudyPlanByAmendingResolution`
           * a mandar en la request POST `is_preloaded = true`;
           * - si no, manda el valor establecido por el servicio.
           */
          const postArguments = isStudyPlanInAprovedArray ? [formatedForm, true] : [formatedForm];
          id = await createStudyPlanByAmendingResolution.apply(this, postArguments);
        }
      } else if (params.id && isNewStudyPlan) {
        // CASO: Se cambia de un plan precargado a un plan desde cero:
        if (formatedForm.amending_resolution) {
          // CASO: Si se modifica un plan precargado CON Resolución Modificatoria:
          await patchCurriculumModified(formatedForm, isNew || isObserved);
          id = params.id;
        } else {
          // CASO: Si se modifica un plan precargado SIN Resolución Modificatoria:
          id = hasModifications ? await createStudyPlanByAmendingResolution(formatedForm) : params.id;
        }
      } else {
        // CASO: si está creando con un plan precargado y no se manda Resolución Modificadora:
        id = studyPlanId;
      }
    }
    //navgo al siguiente paso con el id correspondiente

    //si es observado se envia al studyPlanId.
    /*
    if(isObserved){
      id = studyPlanId;
    }
    */
    navigate(StudyPlanRouteConstants.PASO2(id ? id : studyPlanId));
  };

  // Se ejecuta cuando se cambia la opción del campo 'Plan de Estudios'
  const handleChangeStudyPlan = async (value) => {
    if (value && value.id) {
      setStudyPlanId(value.id);
      setOriginalStudyPlanId(value.id);
      await getCurriculumByIdData(value.id);
      setDisableForm(true); // Ésto provoca el useEffect[disableForm] que ejecuta blockFormControlss()
      setIsNewStudyPlan(false);
    } else if (value && !value.id) {
      // Ésto sólo se ejecuta cuando se elije la opción
      // 'Seleccione esta opción si quiere ingresar un nuevo plan'
      setActivateNewStudyPlan(true);
      setIsNewStudyPlan(true);
      const emptyForm = getEmptyForm();
      emptyForm.id = value;
      initForm(emptyForm);
    } else {
      // Ésto se ejecuta cuando se deselecciona la opción actual
      // y queda vacío el campo.
      initForm(getEmptyForm());
    }
  };

  const blockFormControls = () => {
    if (config) {
      setConfig((prevConfig) =>
        prevConfig.map((e) => {
          let x = { ...e };
          if (x.field === 'study_plan_name') {
            x.disabled = params.id;
          } else if (x.field != 'id' && x.field != 'amending_resolution') {
            x.disabled = true;
          } else {
            x.disabled = false;
          }
          return x;
        })
      );
    }
  };

  const setPreLoadControls = (isNewOrObserved) => {
    const isOldProcessStudyPlan = isNewStudyPlan === null;
    const isNewProcessStudyPlan = isNewStudyPlan === false;
    if (
      // Si es del proceso de asociación ANTERIOR y a. está APROBADO ó b. TIENE Resolución Modificatoria:
      ((!isNewOrObserved || formData.amending_resolution) && isOldProcessStudyPlan) ||
      // Si es del proceso NUEVO y a. está APROBADO ó b. NO ESTÁ APROBADO:
      ((!isNewOrObserved || isNewOrObserved) && isNewProcessStudyPlan) ||
      // Si es creado de un plan NO precargado que ESTÁ APROBADO:
      (!isNewOrObserved && isNewStudyPlan) ||
      // Si es creado de un plan NO precargado que está APROBADO y se le agrega una Resolución Modificatoria:
      (isStudyPlanInAprovedArray && formData.amending_resolution)
    ) {
      // Configura los campos para que sólo Resolución Modificatoria sea editable
      setConfig((prevConfig) =>
        prevConfig.map((e) => {
          let x = { ...e };
          x.disabled = true;
          //muestro nombre de titulo
          if (x.field === 'study_plan_name') {
            x.hidden = false;
          }
          //oculto select
          if (x.field === 'id') {
            x.hidden = formData.amending_resolution;
            x.disabled = formData.amending_resolution;
          }
          if (x.field === 'amending_resolution') {
            // x.disabled = !formData.amending_resolution;
            x.disabled = false;
          }
          if (x.field === 'years') {
            x.hidden = false;
          }
          return x;
        })
      );
    } else {
      // Configura los campos para que todos sean editables
      setConfig((prevConfig) =>
        prevConfig.map((e) => {
          let x = { ...e };
          x.disabled = false;
          //muestro nombre de titulo
          if (x.field === 'study_plan_name') {
            x.hidden = false;
          }
          //oculto select
          if (x.field === 'id') {
            x.hidden = true;
          }
          if (x.field === 'years') {
            x.hidden = false;
          }
          return x;
        })
      );
    }
  };

  const enableNewStudyPlan = () => {
    if (config) {
      setConfig((prevConfig) =>
        prevConfig.map((e) => {
          let x = { ...e };
          if (x.field !== 'amending_resolution') {
            x.disabled = false;
          }
          if (x.field === 'study_plan_name') {
            x.hidden = false;
          }
          if (x.field === 'years') {
            x.hidden = false;
          }
          return x;
        })
      );
    }
  };

  return (
    <>
      <CustomSteps activeStep={1} stepsArray={stepsArray} />
      <CustomStepper steps={2} activeStep={1} />
      <br />
      <Paper elevation={3}>
        <Grid container sx={{ pr: 3, pl: 3 }}>
          {formData && formData.study_plan_status_id === CurriculumStates.OBSERVADO.id && formData.observations ? (
            <ObservationDetailAlert observationDescription={formData.observations[formData.observations.length - 1].observation} />
          ) : null}
        </Grid>
        <Box sx={{ p: 3, pb: 4 }}>
          <Typography variant="h4" color="initial">
            Información general
          </Typography>
          <br />
          {options && config ? (
            <GenericForm options={options} form={form} formControls={config} handleValue={handleValue}></GenericForm>
          ) : null}
        </Box>
      </Paper>
      <br />
      <Box sx={{ float: 'right', display: 'flex', mb: 5 }}>
        <GreyButton
          text="Cancelar"
          action={() => {
            localStorage.removeItem('baseStudyPlan');
            navigate(cancelRedirectLink);
          }}
        />
        {/* TODO Definir que hace este GUARDAR */}
        {/* <GreyButton text="Guardar" action={saveHandleClick} /> */}
        <CustomYellowButton
          title="Continuar"
          action={() => validateFormAndExecutePostAction(nextHandleClick)}
          disabled={hasEmptyRequiredFields}
        />
      </Box>
    </>
  );
};
