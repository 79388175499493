import { getData, postData, deleteData, patchData } from './Services';

/**
 * Obtiene todos los establecimientos para la vista landing del módulo Plan de Estudios.
 * @param {*} page Número de página.
 * @param {*} limit Límite de registros a mostrar en la tabla.
 * @param {*} term Input de búsqueda.
 * @param {*} studyPlanStatusId ID del estado.
 * @returns
 */
export const getAllEstablishments = async (page, limit, term, studyPlanStatusId) => {
  // hay que modificar el EP pero la lógica es la misma
  const res = await getData(
    `/establishment/GroupByEstablishmentByStudyPlan?page=${page}&limit=${limit}${term ? `&term=${term}` : ''}${
      studyPlanStatusId ? `&studyPlanStatusId=${studyPlanStatusId}` : ''
    }`
  );
  return res;
};

export const getAllCurriculums = async (page = 1, limit = 10, establishmentId, studyPlanStatusId, studyPlan) => {
  // Guardamos el estado de plan de estudios por el que se filtra
  // Para que al ingresar a DETALLE esté disponible este dato y se
  // pueda calcular en el BE el ID del plan de estudios siguiente
  localStorage.setItem('studyPlanStatusId', studyPlanStatusId);
  const res = await getData(
    `/studyPlans/filter?page=${page}&limit=${limit}${establishmentId ? `&establishmentId=${establishmentId}` : ''}${
      studyPlanStatusId ? `&studyPlanStatusId=${studyPlanStatusId}` : ''
    }${studyPlan ? `&studyPlan=${studyPlan}` : ''}`
  );
  return res;
};

export const getCurriculumById = async (studyPlanId) => {
  const establishmentId = localStorage.getItem('establishmentId');
  const studyPlanStatusId = localStorage.getItem('studyPlanStatusId');
  const studyPlanQP = studyPlanStatusId ? `studyPlanStatusId=${studyPlanStatusId}` : '';
  if (!establishmentId) throw new Error('Falta el ID de establecimiento');
  const res = await getData(`/studyPlans/byId/?studyPlanId=${studyPlanId}&establishmentId=${establishmentId}&${studyPlanQP}`);
  return res;
};

export const postAssociateCurriculum = async (studyPlanId, establishmentId) => {
  const res = await postData(`/studyPlans/associate?study_plan_id=${studyPlanId}&establishment_id=${establishmentId}`);
  return res;
};

export const getCurriculumsTitles = async (establishmentId) => {
  const res = await getData('/studyPlans/titles?establishment_id=' + establishmentId);
  return res;
};

export const postCurriculum = async (body) => {
  const res = await postData('/studyPlans', body);
  return res;
};

export const deleteCurriculum = async (studyPlanId) => {
  const res = await deleteData(`/studyPlans/byId/?studyPlanId=${studyPlanId}`);
  return res;
};
export const disassociateCurriculum = async (studyPlanId, establishmentId) => {
  const res = await deleteData(`/studyPlans/disassociate?study_plan_id=${studyPlanId}&establishment_id=${establishmentId}`);
  return res;
};

export const patchCurriculum = async (body) => {
  const res = await patchData('/studyPlans', body);
  return res;
};

export const getStatusCurriculum = async () => {
  const res = await getData('/studyPlans/status');
  return res;
};

export const getModalityCurriculum = async () => {
  const res = await getData('/studyPlans/modality');
  return res;
};

export const getCurricularBoxById = async (studyPlanId) => {
  const res = await getData(`/studyPlans/curricularBox?studyPlanId=${studyPlanId}`);
  return res;
};

export const CreateCurriculum = async (body) => {
  const res = await postData(`/studyPlans/`, body);
  return res;
};

export const patchStepTwo = async (body) => {
  const res = await patchData(`/studyPlans/step2`, body);
  return res;
};

//OBSERVACIONES
export const patchCurriculumObserve = async (body) => {
  const res = await patchData(`/studyPlans/switchState`, body);
  return res;
};

export const postCurriculumObserve = async (body) => {
  const res = await postData(`/studyPlans/addEdit/observation`, body);
  return res;
};

export const deleteCurriculumObserve = async (observationId) => {
  const res = await deleteData(`/studyPlans/observation?observation_id=${observationId}`);
  return res;
};
