import { Box, Grid, Paper, Typography } from '@mui/material';
import moment from 'moment/moment';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomYellowButton } from '../../../components/CustomYellowButton';
import { GenericForm } from '../../../components/GenericForm';
import { GreyButton } from '../../../components/GreyButton';
import analyticalStepTwoConfig from '../../../configs/analyticalStepTwoConfig.json';
import { AnalyticalStepTwoContext } from '../../../context/Analytical/AnalyticalContext';
import { useGenericForm } from '../../../hooks/useGenericForm';
import usePrevious from '../../../hooks/usePrevious';
import { AnalyticRouteConstants } from '../../../routes/RoutesConstants';
import { AnalyticalModalNewOriginal } from './AnalyticalModalNewOriginal';
import { AnalyticalModalPartialCertificate } from './AnalyticalModalPartialCertificate';
import { AnalyticalModalTwoCopies } from './AnalyticalModalTwoCopies';
import { AppContext } from '../../../context/global/AppContext';
import { getStoragedBoolean } from '../../../helpers/getStoragedBoolean';
import { createAlertItem } from '../../../tools/alerts';
import { USER_MESSAGES } from '../../../constants/UserMessages';
import CanceledAnalyticTag from '../../../components/CanceledAnalyticTag';

let { formControls } = analyticalStepTwoConfig;
//horrible pero el refactor de esto es larguisimo
const formControlsCopy = formControls.map((x) => ({ ...x }));

const ORIGINAL = 1;
const PARCIALES = 2;

const IMPLEMENTATION = '10/31/2023';

export const AnalyticalStepTwoForm = () => {
  const {
    patchStudentStepTwoData,
    options,
    stepTwoInfo,
    setCopieReasonSelected,
    copieReasonSelected,
    getStudyPlanLinkStatus,
    previewInfo,
    studyPlanLinkStatus
  } = useContext(AnalyticalStepTwoContext);
  const {
    form,
    initForm,
    handleValue,
    validateFormAndExecutePostAction,
    setConfig,
    config,
    hasModifications,
    formIsEmpty,
    setHasModifications,
    hasEmptyRequiredFields
  } = useGenericForm(formControls);
  // Modal
  const [openModal, setOpenModal] = useState(false);
  const [openNewOriginalModal, setNewOriginalModal] = useState(false);
  const [openPartialCertificateModal, setOpenPartialCertificateModal] = useState(false);
  const [jurisdiction, setJurisdiction] = useState('');
  const [preImplementation, setPreImplementation] = useState(false);
  const [copieTypeSelected, setCopieTypeSelected] = useState(1);
  const [egressDate, setEgressDate] = useState('');
  const [broadcastDate, setBroadcastDate] = useState('');
  const handleOpenModal = () => setOpenModal(true);

  const navigate = useNavigate();
  const { user } = useContext(AppContext);
  const { id: analyticId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const previousCopySelected = usePrevious(copieTypeSelected);
  const [studyPlanLinkAlert, setStudyPlanLinkAlert] = useState();
  const [chosenStudyPlanId, setChosenStudyPlanId] = useState();
  // La primera opción la ejecuta con ADMINS y LEGISLACIÓN y la segunda con los EQUIPOS DE CONDUCCIÓN:
  const isEstablishmentTerciary =
    getStoragedBoolean('analyticalEstablishmentIsTerciary') ||
    user.establishments.filter((establishment) => establishment.id === user.establishmentId)[0].is_terciary;
  const isFromLMD = stepTwoInfo?.analytic_student?.isFromLMD;

  //ESPANTOSO / cuando haya tiempo hay que rehacer este formulario
  useEffect(() => {
    return () => {
      formControls = formControlsCopy;
    };
  }, []);

  useEffect(() => {
    if (stepTwoInfo) {
      // Settea por defecto el valor de COPIA a 'Original':
      // (Aunque se settee esto al inicial el form, igual no lo muestra
      // en el desplegable cuando se empieza el paso 2 desde cero; es
      // necesario settear el `value` cuando se mapea el formControls más abajo)
      if (stepTwoInfo.study_plan_id === '' || !stepTwoInfo.study_plan_id) {
        setConfig((prevConfig) => {
          return prevConfig.map((x) => ({ ...x, disabled: x.field === 'title' || x.field === 'type_id' }));
        });
      }
      if (stepTwoInfo.copies === '' || !stepTwoInfo.copies) {
        const stepTwoInfoCopy = { ...stepTwoInfo, copies: '1' };
        initForm(stepTwoInfoCopy);
      } else {
        initForm(stepTwoInfo);
      }
      if (isFromLMD) {
        const uneditableFields = ['studyPlanId', 'title', 'matrix_number', 'folio_number', 'type_id'];
        setConfig((prevConfig) => {
          return prevConfig.map((x) => ({ ...x, disabled: uneditableFields.includes(x.field) }));
        });
      }
      if (!previewInfo?.not_editable && !isFromLMD) {
        setConfig((prevConfig) => {
          return prevConfig.map((x) => ({ ...x, disabled: x.field === 'title' || x.field === 'type_id' }));
        });
      }
      setCopieTypeSelected(stepTwoInfo?.analytic_types?.id || 1);
    }
  }, [stepTwoInfo, previewInfo]);

  useEffect(() => {
    if (egressDate && moment(form.egress_date).isValid()) {
      if (new Date(form.egress_date) <= new Date(IMPLEMENTATION)) {
        setPreImplementation(true);
        if (copieTypeSelected === ORIGINAL) {
          handleOpenNewIdentityModal();
        }
        if (copieTypeSelected > PARCIALES) {
          handleOpenModal();
        }
      }
    }
  }, [egressDate]);

  const handleCloseModal = () => {
    let newForm = { ...form };
    newForm.type_id = copieTypeSelected;
    initForm(newForm);
    setOpenModal(false);
    // handlePrevious();
  };

  const handleCloseNewOriginalModal = () => {
    let newForm = { ...form };
    newForm.type_id = ORIGINAL;
    initForm(newForm);
    setNewOriginalModal(false);
  };

  const handleCloseModalPartialExitWithouSave = () => {
    setCopieTypeSelected(previousCopySelected);
    let newForm = { ...form };
    newForm.type_id = previousCopySelected;
    initForm(newForm);
    setOpenPartialCertificateModal(false);
  };
  const handleCloseModalPartial = () => {
    setOpenPartialCertificateModal(false);
  };

  const handleChangeReason = (event) => {
    setCopieReasonSelected(event.target.value);
  };
  const handleChangeOriginalReason = (event) => {
    setCopieReasonSelected(event.target.value);
    setNewOriginalModal(event.target.value ? true : false);
  };
  const handleChangeJurisdiction = (event) => {
    setJurisdiction(event.target.value);
  };

  const calculateAlertMessage = (linksStatus) => {
    const alertMessage = { existsInAnalyticModule: 'Analíticos', existsInHistoryModule: 'Histórico' };
    let foundStatus = [];
    for (const status in linksStatus) {
      linksStatus[status] === true && foundStatus.push(status);
    }
    const statusAlerts = foundStatus.map((status, index) => {
      return createAlertItem(
        status,
        `El alumno/a ya se encuentra asociado a este plan de estudios en el módulo ${alertMessage[status]}.`,
        index
      );
    });
    setStudyPlanLinkAlert(statusAlerts);
  };

  const handleNext = async (form) => {
    if (hasModifications) {
      if (isEstablishmentTerciary) {
        const statusEvaluation = await handleSave(form);
        if (!statusEvaluation) navigate(AnalyticRouteConstants.PASO3(analyticId));
      } else {
        await patchStudentStepTwoData(form);
        navigate(AnalyticRouteConstants.PASO3(analyticId));
      }
    } else if (!hasEmptyRequiredFields) {
      navigate(AnalyticRouteConstants.PASO3(analyticId));
    }
  };

  const handlePrevious = async (form) => {
    if (hasModifications && !studyPlanLinkStatus) await patchStudentStepTwoData(form);
    navigate(AnalyticRouteConstants.PASO1(analyticId));
  };

  const handleSave = async (form) => {
    const isStudyPlanChanged = chosenStudyPlanId;
    const studentId = previewInfo.id;
    // Esta validación se hace para planes de estudio de establecimientos TERCIARIOS solamente:
    if (isStudyPlanChanged && isEstablishmentTerciary) {
      const studyPlanLinkStatus = await getStudyPlanLinkStatus(studentId, chosenStudyPlanId);
      const studyPlanLinkExists = Object.keys(studyPlanLinkStatus).some((status) => studyPlanLinkStatus[status]);
      if (studyPlanLinkExists) {
        calculateAlertMessage(studyPlanLinkStatus);
      } else {
        await patchStudentStepTwoData(form);
      }
      return studyPlanLinkExists;
    } else {
      await patchStudentStepTwoData(form);
      return false;
    }
  };

  //esto no esta bien hecho, este map se esta ejecutando en todo render y esta pisando el json (igual anda pero no es la mejor forma)
  // FUTURA SOLUCION : SE DEBE REALIZAR UN SETCONFIG CON ESTAS FUNCIONES CAMBIADAS VER LOS OTROS EJEMPLOS, AL GENERIC FORM HAY QUE PASARLE
  // LA CONFIGURACION DEL HOOK, NO ESTO DIRECTAMENTE
  formControls.map((e) => {
    if (previewInfo?.not_editable) {
      // El campo "fecha de egreso" no estará habilitado si se está generando un
      //Certificado de estudios parciales
      if (stepTwoInfo && stepTwoInfo.not_editable && e.field !== 'broadcast_date' && e.field !== 'observation') {
        e.disabled = true;
        return;
      }
      if (e.field === 'egress_date') {
        if (copieTypeSelected === PARCIALES) {
          e.disabled = true;
          e.required = false;
        } else {
          e.disabled = false;
          e.required = true;
        }
        e.action = (value, field) => {
          const isDateNull = egressDate === null;
          handleValue(value, field);
          setEgressDate(value);
          let brD = broadcastDate ? broadcastDate : stepTwoInfo.broadcast_date;
          if (moment(value).isAfter(brD)) {
            if (isDateNull) {
              // Borra el campo Fecha de Emisión sólo si el campo Fecha de Egreso estaba vacío
              handleValue('', 'broadcast_date');
            }
            enqueueSnackbar('La fecha de emisión no puede ser anterior a la fecha de egreso.', { variant: 'warning' });
          } else {
            handleValue(moment(value), field);
          }
        };
      }
      if (e.field === 'broadcast_date') {
        e.action = (value, field) => {
          handleValue(value, field);
          setBroadcastDate(value);
          let egD = egressDate ? egressDate : stepTwoInfo.egress_date;
          if (!moment(value).isBefore(egD)) {
            handleValue(moment(value), field);
          }
        };
      }
      if (e.optionsKey === 'copies') {
        e.action = (value, field) => {
          handleValue(value, field);
          setCopieTypeSelected(value);
          if (value === PARCIALES) {
            handleValue(null, 'egress_date');
            setOpenPartialCertificateModal(true);
          }
          if (preImplementation) {
            if (value > PARCIALES) {
              handleOpenModal();
            }
            if (value === ORIGINAL) {
              handleOpenNewIdentityModal();
            }
          }
        };
        // se settea el valor a MOSTRAR en el seleccionable
        e.value = 1;
      }
    }
  });

  const handleOpenNewIdentityModal = () => {
    setNewOriginalModal(true);
  };

  const getValueSelectStudyPlan = (value) => {
    // settea el valor del ID del plan de estudio para poder usarlo en la verificación de preexistencia:
    setChosenStudyPlanId(value);

    let newForm = { ...form };
    newForm.studyPlanId = value;
    newForm.title = options.studyPlans.filter((x) => x.id === value)[0].title;
    initForm(newForm);
    //esto deberia ser generico pero no me queda otra porque tengo que rehacer todo el componente basicamente jaja
    setHasModifications(true);
  };
  let selectStudyPlan = formControls.filter((x) => x.field === 'studyPlanId')[0];

  //esto tampoco esta bien
  selectStudyPlan.action = getValueSelectStudyPlan;

  return (
    <>
      <br />
      <Paper elevation={3}>
        <Box sx={{ p: 3, pb: 4 }}>
          <Grid container sx={{ pr: 2 }}>
            <Grid item xs={6}>
              <Typography variant="h4" color="initial">
                Información del plan de estudios
              </Typography>
            </Grid>
            {previewInfo?.hasBeenCanceled && !isFromLMD ? <CanceledAnalyticTag /> : null}
            {isFromLMD && createAlertItem(true, USER_MESSAGES.CREATED_IN_LMD)}
            {studyPlanLinkAlert && (
              <Grid container rowSpacing={1} sx={{ maxWidth: '50%' }}>
                {studyPlanLinkAlert.map((linkAlert) => linkAlert)}
              </Grid>
            )}
          </Grid>
          <Grid>
            {options && form ? (
              <GenericForm formControls={config} options={options} handleValue={handleValue} form={form}></GenericForm>
            ) : (
              ''
            )}
          </Grid>
        </Box>
      </Paper>

      <br />

      <Box sx={{ float: 'right', display: 'flex', mb: 5 }}>
        <GreyButton text="Anterior" action={() => validateFormAndExecutePostAction(handlePrevious)} />
        <Box sx={{ mr: 1 }}>
          <GreyButton text="Guardar" disabled={!hasModifications} action={() => validateFormAndExecutePostAction(handleSave)} />
        </Box>
        <CustomYellowButton
          title="Continuar"
          disabled={formIsEmpty || form.studyPlanId == null}
          action={() => validateFormAndExecutePostAction(handleNext)}
        />
      </Box>
      {/* Modal */}
      <AnalyticalModalTwoCopies
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        copieReason={copieReasonSelected}
        handleChangeReason={handleChangeReason}
        options={options}
      />
      <AnalyticalModalNewOriginal
        openModal={openNewOriginalModal}
        handleCloseModal={handleCloseNewOriginalModal}
        // studentName={`${stepTwoInfo?.analytic_student?.name} ${stepTwoInfo?.analytic_student?.last_name}`}
        copieReason={copieReasonSelected}
        handleChangeReason={handleChangeOriginalReason}
        options={options}
      />
      <AnalyticalModalPartialCertificate
        openModal={openPartialCertificateModal}
        handleCloseModal={handleCloseModalPartial}
        handleCloseModalPartialExitWithouSave={handleCloseModalPartialExitWithouSave}
        jurisdiction={jurisdiction}
        handleChangeReason={handleChangeJurisdiction}
      />
    </>
  );
};
