import { Grid, Typography } from '@mui/material';
import { CustomBreadcrumbs } from '../../../components/CustomBreadcrumbs';
import { CustomStepper } from '../../../components/CustomStepper';
import { CustomSteps } from '../../../components/CustomSteps';
import { PreviousDataComponent } from '../../../components/PreviousDataComponent';
import { AnalyticalStepThreeContext } from '../../../context/Analytical/AnalyticalContext';
import { AnalyticalStepThreeState } from '../../../context/Analytical/StepThree/AnalyticalStepThreeState';
import { AnalyticalStepThreeForm } from './AnalyticalStepThreeForm';
import { stepsArray } from '../../../constants/StepsArray';
import { AnalyticRouteConstants } from '../../../routes/RoutesConstants';
import { useAuthHelper } from '../../../hooks/useAuthHelper';
import StepThreeAlert from './StepThreeAlert';
import { useContext } from 'react';
import { AppContext } from '../../../context/global/AppContext';

export const AnalyticalStepThreePage = () => {
  const { isUserAdminOrLegalization } = useAuthHelper();
  const { getEstablishmentData } = useContext(AppContext);
  const { establishmentId, establishmentName } = getEstablishmentData(isUserAdminOrLegalization());
  const baseLink = isUserAdminOrLegalization()
    ? AnalyticRouteConstants.BUSCAR_PARAMS(establishmentId, establishmentName)
    : AnalyticRouteConstants.BUSCAR;
  return (
    <AnalyticalStepThreeState>
      <Grid sx={{ background: 'linear-gradient(180deg, rgba(230,235,240,1) 30%, rgba(243,246,249,1) 30%)', p: '40px' }}>
        <br />
        <CustomBreadcrumbs base={'Analítico'} now={'Editar analítico'} baseLink={baseLink} />
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h3" color="initial">
              Caja curricular
            </Typography>
            <br />
            <CustomSteps activeStep={3} stepsArray={stepsArray} />
            <CustomStepper steps={3} activeStep={3} />
          </Grid>
          <StepThreeAlert />
        </Grid>
        <br />
        <AnalyticalStepThreeContext.Consumer>
          {(ctx) => {
            return ctx.previewInfo ? (
              <PreviousDataComponent
                name={`${ctx.previewInfo.analytic_student.name} ${ctx.previewInfo.analytic_student.last_name}`}
                docType={ctx.previewInfo.analytic_student.document_type.name}
                document={ctx.previewInfo.analytic_student.document_number}
                studyPlan={ctx.previewInfo.study_plan?.study_plan_name}
                amendingResolution={ctx.previewInfo.study_plan?.amending_resolution}
              />
            ) : null;
          }}
        </AnalyticalStepThreeContext.Consumer>
        <AnalyticalStepThreeForm />
      </Grid>
    </AnalyticalStepThreeState>
  );
};
