import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import { Tooltip, Badge } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import { ThemeProvider } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BA from '../assets/images/logo_navbar.svg';
import { AppContext } from '../context/global/AppContext';
import { themeNavbar } from '../styles/Styles';
import { NavbarUser } from './NavbarUser';
import { VALIDADOR } from '../constants/Roles';
import './style.css';

export default function Navbar() {
  const { user, logout, hasPendingNotifications, hasNotifications } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    hasPendingNotifications();
    const interval = setInterval(() => {
      hasPendingNotifications();
    }, 100000);
    return () => clearInterval(interval);
  }, [user]);

  let establishment = user && user.establishmentId ? user.establishments.filter((x) => x.id === user.establishmentId)[0] : null;
  const userData = JSON.parse(localStorage.getItem('user'));
  
  const isValidador = (userData && userData.roles && userData.roles.length > 0) ? (userData.roles[0] == VALIDADOR) : false;
  const numberEstablishment = JSON.parse(localStorage.getItem('user'))?.establishments?.length >= 2;
  if(isValidador){
    if(numberEstablishment){
      establishment = null;
    }
  }

  return (
    <ThemeProvider theme={themeNavbar}>
      <AppBar position="static" style={{ padding: '0.5em', marginBottom: '7px' }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters >
            <Box sx={{ flexGrow: 0, flexDirection: 'row' }}>
              <Avatar sx={{ width: 82, height: 46 }} alt={'BA'} src={BA} variant="square"></Avatar>
            </Box>
            <Box sx={{ flexGrow: 5, marginLeft: '2em' }}>
              <Typography variant="h5" color="dark" component="div" sx={{ fontFamily: 'Nunito', color: '#707070' }}>
                SiTED
              </Typography>
            </Box>
            {establishment && (
              <>
                <Box sx={{ flexGrow: 0, marginRight: '0.5em' }}>
                  <IconButton sx={{ p: 0 }}>
                    <PersonPinCircleIcon />
                  </IconButton>
                </Box>
                <Box mr={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Typography mt={2}  variant="p" color="#38485C" component="div">
                    {establishment.name}
                  </Typography>
                  <Typography alignSelf="flex-start" variant='caption' color="#38485C" component="span">
                    {`(${establishment.address})`}
                  </Typography>
                </Box>
              </>
            )}
            {user ? (
              <>
                <Box sx={{ flexGrow: 0, marginRight: '0.5em' }}>
                  <Badge color="info" variant="dot" invisible={!hasNotifications}>
                    <IconButton onClick={() => navigate('/notificaciones')} sx={{ p: 0 }}>
                      <Tooltip title={hasNotifications ? 'Tiene notificaciones pendientes' : 'Notificaciones'}>
                        <NotificationsIcon />
                      </Tooltip>
                    </IconButton>
                  </Badge>
                </Box>
                <Box mr={2} sx={{ flexGrow: 0 }}>
                  <Typography variant="p" color="#38485C" component="div">
                    Notificaciones
                  </Typography>
                </Box>

                <NavbarUser selectedUser={user} />
                <Box mr={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Typography mt={2} variant="p" color="#38485C" component="div">
                    {user.first_name} {user.last_name}
                  </Typography>
                  <Typography alignSelf="flex-start"  variant="caption" color="#38485C" component="span">
                    {`(${user.position})`}
                  </Typography>
                </Box>
                <Tooltip title="Cerrar sesion">
                  <IconButton onClick={logout}>
                    <LogoutIcon />
                  </IconButton>
                </Tooltip>
              </>
            ) : null}
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
}
