/* eslint-disable no-console */
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import Toast from '../components/Toast';

// eslint-disable-next-line no-undef
const route = process.env.REACT_APP_URL_BACKEND;
//esto no es muy feliz TODO MEJORAR
const PERMISSION_MESSAGE = 'no tiene permisos para realizar esta acción';

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      console.log(error.response);
      if (error.response.data.message !== PERMISSION_MESSAGE) {
        localStorage.clear();
        Toast.error('No se encuentra autorizado para realizar la acción, por favor vuelva a loguearse');
        alert('Será redireccionado a la página de inicio. Presione aceptar para continuar.');
        window.location.reload();
      } else {
        Toast.error('No tiene los permisos para realizar la siguiente acción');
      }
    } else if (error?.response?.data?.statusCode == 400) {
      return Toast.error(`${error?.response?.data?.message}`);
      // return Promise.reject(error);
    } else if (error?.response?.data?.statusCode == 409) {
      return Toast.error(`${error?.response?.data?.message}`);
      // return Promise.reject(error);
    } else if (error.response && error.response.status === 403) {
      // Si llegamos a recibir un 403, algo salió mal con nuestro refresh proactivo
      Toast.error('Error de autorización. Por favor, vuelva a iniciar sesión');
      localStorage.clear();
      window.location.reload();
    } else if (error.response && error.response.status === 400) {
      Toast.error('Ha ocurrido un error en el backend, por favor comunicarse con soporte');
      console.log(error);
    } else if (error.response && error.response.status === 404) {
      Toast.error(error.response.data.message);
      console.log(error);
    } else if (error.response && error.response.status === 500) {
      Toast.error('Error interno en el servidor, por favor comunicarse con soporte');
      console.log(error);
    } else if (error) {
      Toast.error('Ha ocurrido un error en la aplicación, por favor comunicarse con soporte');
      // eslint-disable-next-line no-console
      console.log(error);
    } else if (error.response) {
      Toast.error('Ha ocurrido un error en la aplicación, por favor comunicarse con soporte');
      // eslint-disable-next-line no-console
      console.log(error.response);
    }
    return Promise.reject(error);
  }
);

export const getData = async (url, element = '') => {
  let res = await trackPromise(
    axios.get(route + url + element, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
  );
  if (res.statusText === 'OK') {
    return res.data;
  }
};

export const getDataWithNoTracking = async (url, element = '') => {
  let res = await axios.get(route + url + element, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  });
  if (res.statusText === 'OK') {
    return res.data;
  }
};

export const putData = async (url, body) => {
  let res = await trackPromise(
    axios.put(`${route}${url}`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
  );
  if (res.estatusText === 'OK') {
    Toast.success('El registro fue actualizado correctamente');
    return res.data;
  }
};

export const postData = async (url, body, hideToast) => {
  let res = await trackPromise(
    axios.post(`${route}${url}`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
  );
  if (res.status === 201) {
    if (!hideToast) Toast.success('El registro fue creado correctamente');
    return res.data;
  }
};

export const deleteData = async (url) => {
  console.log(localStorage.getItem('token'));
  let res = await trackPromise(
    axios.delete(`${route}${url}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
  );
  if (res.status === 200) {
    Toast.success('El registro fue eliminado correctamente');
    return res.data;
  } else {
    console.error(res);
    return res;
  }
};

export const patchData = async (url, body, hideToast) => {
  let res = await trackPromise(
    axios.patch(`${route}${url}`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
  );
  if (res.statusText === 'OK') {
    if (!hideToast) Toast.success('El registro fue actualizado correctamente');
    return res.data;
  } else {
    console.error(res);

    return res;
  }
};
