import { Autocomplete, FormControl, TableCell, TextField } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { handleRowNavigation, KEYS } from '../../../helpers/curricularTableNavigation';

export const EstablishmentCell = ({ controlConfig, updateGrid }) => {
  const { initialValue, rowId, field, options, width, optionsText, disabled = false, setRefElement, onCellNavigation } = controlConfig;
  const isSelectingAnOption = useRef(false);

  const [inputValue, setInputValue] = useState(initialValue);

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  const onAutocompleteEstablishmentSelect = () => {
    updateGrid(inputValue, rowId, field);
  };

  return (
    <TableCell width={width}>
      <FormControl fullWidth>
        <Autocomplete
          fullWidth
          disabled={disabled}
          freeSolo
          id="combo-box-demo"
          onBlur={onAutocompleteEstablishmentSelect}
          value={inputValue || ''}
          inputValue={inputValue || ''}
          onInputChange={(e) => {
            setInputValue(e.target.value);
          }}
          onChange={(e, value) => {
            setInputValue(value);
          }}
          options={options || []}
          renderInput={(params) => (
            <TextField
              tabIndex={0}
              ref={(element) => setRefElement(element)}
              onKeyDown={(event) => {
                handleRowNavigation(event, isSelectingAnOption, onCellNavigation, KEYS.ESTABLISHMENT_TAB);
              }}
              value={initialValue}
              {...params}
              variant="standard"
              InputProps={{
                ...params.InputProps,
                disableUnderline: true
              }}
              inputProps={{
                ...params.inputProps,
                maxLength: 15
              }}
            />
          )}
          noOptionsText={optionsText ? optionsText : 'No hay opciones que coincidan con la búsqueda'}
        />
      </FormControl>
    </TableCell>
  );
};
