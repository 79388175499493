import { CustomBreadcrumbs } from '../../../components/CustomBreadcrumbs';
import { Actions } from '../../../constants/Actions';
import { CurriculumDetailState } from '../../../context/Curriculum/CurriculumDetailState';
import { useAuthHelper } from '../../../hooks/useAuthHelper';
import { BodyDetail } from './BodyDetail';
import { CurriculumBox } from './CurriculumBox';

export const CurriculumDetailPage = () => {
  const auth = useAuthHelper();
  const isUserAdminOrLegalization = auth.isAllowed([Actions.READ_GLOBAL_STUDYPLAN]);
  const baseLink = isUserAdminOrLegalization
    ? `/planEstudio/buscar/${localStorage.getItem('establishmentId')}/${localStorage.getItem('establishmentName')}`
    : '/planEstudio/buscar';
  return (
    <CurriculumDetailState>
      <CustomBreadcrumbs
        base="Plan de estudios"
        now="Detalle"
        baseLink={baseLink}
        onClick={() => localStorage.removeItem('studyPlanStatusId')}
      />
      <BodyDetail />
      <CurriculumBox />
    </CurriculumDetailState>
  );
};
