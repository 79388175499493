import { Autocomplete, FormControl, TableCell, TextField } from '@mui/material';
import { useMemo, useRef } from 'react';
import { handleRowNavigation, KEYS } from '../../../helpers/curricularTableNavigation';

export const StaticAutocompleteCell = ({ controlConfig, updateGrid }) => {
  const { initialValue, rowId, field, options, width, optionsText, disabled = false, setRefElement, onCellNavigation } = controlConfig;
  const isSelectingAnOption = useRef(false);

  const value = useMemo(() => {
    return options ? options.find((x) => x.id === initialValue) : null;
  }, [initialValue, options]);

  const onAutoComplete = (value) => {
    updateGrid(value ? value.id : null, rowId, field);
  };

  return (
    <TableCell width={width}>
      <FormControl fullWidth>
        <Autocomplete
          fullWidth
          disabled={disabled}
          id="combo-box-demo"
          onChange={(event, value) => onAutoComplete(value)}
          value={value ? value : null}
          options={options || []}
          getOptionLabel={(x) => {
            return x && x.name ? x.name : '';
          }}
          renderInput={(params) => (
            <TextField
              tabIndex={0}
              ref={(element) => setRefElement(element)}
              onKeyDown={(event) => {
                handleRowNavigation(event, isSelectingAnOption, onCellNavigation, KEYS.TAB);
              }}
              value={value}
              {...params}
              variant="standard"
              InputProps={{
                ...params.InputProps,
                disableUnderline: true
              }}
            />
          )}
          isOptionEqualToValue={(option, value) => {
            return option && value.id ? parseInt(option.id) === parseInt(value.id) : false;
          }}
          noOptionsText={optionsText ? optionsText : 'No hay opciones que coincidan con la búsqueda'}
        />{' '}
      </FormControl>
    </TableCell>
  );
};
