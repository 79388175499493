/**
 * Constante que almacena mensajes para mostrarle al user.
 *
 * - BLOCKED_LMD: Mensaje indicando que un analítico no está disponible para edición ni firma debido a que los datos están siendo editados en Libro Matriz Digital.
 * - CREATED_IN_LMD: Mensaje indicando que un dato no puede ser editado en SITED, debe modificarse en Libro Matriz Digital.
 */
export const USER_MESSAGES = {
  BLOCKED_LMD:
    'Este analítico no está disponible para edición ni firma debido a que los datos están siendo editados en Libro Matriz Digital.',
  CREATED_IN_LMD:
    'Los campos deshabilitados contienen datos provenientes de Libro Matriz Digital y no pueden ser modificados en esta aplicación.'
};
