import { useContext } from 'react';
import { AnalyticalStepThreeContext } from '../../../context/Analytical/AnalyticalContext';
import { createAlertItem } from '../../../tools/alerts';
import { USER_MESSAGES } from '../../../constants/UserMessages';
import { Grid } from '@mui/material';

const StepThreeAlert = () => {
  const { previewInfo } = useContext(AnalyticalStepThreeContext);
  return (
    <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
      {previewInfo?.analytic_student.isFromLMD && createAlertItem(true, USER_MESSAGES.CREATED_IN_LMD, 'warning', { mb: 0, mt: 0 })}
    </Grid>
  );
};

export default StepThreeAlert;
