import { Box, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { CalificationCell } from './CalificationCell';
import { CurricularBoxHeaderTable } from './CurricularBoxHeaderTable';
import { EstablishmentCell } from './EstablishmentCell';
import { StaticAutocompleteCell } from './StaticAutocompleteCell';
import { useRef } from 'react';
import { calcNextCellId, calcRefId } from '../../../helpers/curricularTableNavigation';

export const CurricularBoxTable = ({ tableConfig, rows, selectOptions, blockRow = false, updateRows, canLoadMaximumQualification }) => {
  //lo del clear header deberia estar en un hook
  const { subjectConfig, calificationConfig, conditionConfig, monthConfig, yearConfig, establishmentConfig } = tableConfig;
  /**
   * Array de ref de cada celda de la tabla.
   */
  const cellRefs = useRef([]);

  const updateCurricularBox = (value, rowId, field) => {
    let newData = [...rows];
    let row = newData.find((x) => x.id === rowId);
    row[field] = value;
    row.edited = true;
    updateRows(newData);
  };

  const updateMultipleColumn = (value, field) => {
    updateRows((prev) => {
      return prev.map((x) => {
        x[field] = value ? value : null;
        x.edited = true;
        return x;
      });
    });
  };

  const displayTable = rows.length ? 'block' : 'none';

  const moveToNextCell = (rowId, colIndex, pressedKey) => {
    const nextCell = calcNextCellId(rowId, colIndex, pressedKey);
    const nextCellRef = cellRefs.current[nextCell];
    if (nextCellRef) {
      nextCellRef.querySelector('input').focus();
    } else {
      const firstTableCellId = calcRefId(rows[0].id, colIndex === 4 ? 0 : colIndex);
      cellRefs.current[firstTableCellId].querySelector('input').focus();
    }
  };

  const setCellRef = (id, ref) => (cellRefs.current[id] = ref);

  const formatConfig = (config, row) => {
    const refId = calcRefId(row.id, config.colIndex);
    return {
      ...config,
      initialValue: row[config.field],
      rowId: row.id,
      options: selectOptions[config.optionsKey],
      disabled: blockRow,
      onCellNavigation: (pressedKey) => {
        moveToNextCell(row.id, config.colIndex, pressedKey);
      },
      setRefElement: (element) => setCellRef(refId, element)
    };
  };

  return (
    <Box>
      <Box sx={{ display: displayTable }}>
        <TableContainer size="small" aria-label="a dense table" sx={{ width: '100%' }}>
          <Table aria-label="dense table" size="small">
            <TableHead sx={{ backgroundColor: '#F3F6F9' }}>
              <CurricularBoxHeaderTable
                tableConfig={tableConfig}
                blockRow={blockRow}
                updateMultipleColumn={updateMultipleColumn}
                selectOptions={selectOptions}
              />
            </TableHead>
            <TableBody sx={{ width: '100%' }}>
              {rows.map((row) => (
                <TableRow key={`${row.id}`}>
                  <TableCell width={subjectConfig.width}>
                    <Typography>{row[subjectConfig.field]}</Typography>
                  </TableCell>
                  <CalificationCell
                    controlConfig={formatConfig(calificationConfig, row)}
                    updateGrid={updateCurricularBox}
                    canLoadMaximumQualification={canLoadMaximumQualification}
                  />
                  <StaticAutocompleteCell controlConfig={formatConfig(conditionConfig, row)} updateGrid={updateCurricularBox} />
                  <StaticAutocompleteCell controlConfig={formatConfig(monthConfig, row)} updateGrid={updateCurricularBox} />
                  <StaticAutocompleteCell controlConfig={formatConfig(yearConfig, row)} updateGrid={updateCurricularBox} />
                  <EstablishmentCell controlConfig={formatConfig(establishmentConfig, row)} updateGrid={updateCurricularBox} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <br></br>
    </Box>
  );
};
