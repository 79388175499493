import { Box, Typography } from '@mui/material';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { useMemo } from 'react';
import { steps } from '../tools/stepsAnalytical';
import { LEGALIZADO } from '../constants/AnalyticalStates';

export const VerticalStepper = ({ state }) => {
  const activeStep = useMemo(() => {
    for (let i = 0; i < steps.length; i++) {
      if (steps[i].condition(state)) {
        if (state === LEGALIZADO) return i + 1;
        return i;
      }
    }
    return 0;
  }, [state]);

  return (
    <Box sx={{ maxWidth: 400, px: 2 }}>
      <Typography variant="body1">Seguimiento</Typography>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step) => {
          return (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
              <StepContent>
                <Typography>{step.description}</Typography>
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};
