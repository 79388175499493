import BorderColorIcon from '@mui/icons-material/BorderColor';
import Edit from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Send from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import HistoryIcon from '@mui/icons-material/History';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableAction } from '../../../components/TableAction';
import { TableGenericComponent } from '../../../components/TableGenericComponents';
import { Actions, Roles } from '../../../constants/Actions';
import { AnalyticalContext } from '../../../context/Analytical/AnalyticalContext';
import { analyticStateValidator } from '../../../helpers/analyticStateValidator';
import { stateHtmlAnalytics, stateHtmlAnalyticsAvatar } from '../../../helpers/StateHtmlColors';
import { useAuthHelper } from '../../../hooks/useAuthHelper';
import { AnalyticRouteConstants } from '../../../routes/RoutesConstants';
import { Modal } from '../../../components/Modal';
import { CustomYellowButton } from '../../../components/CustomYellowButton';
import { GreyButton } from '../../../components/GreyButton';
import { AppContext } from '../../../context/global/AppContext';
import { getStoragedBoolean } from '../../../helpers/getStoragedBoolean';
import AnalyticalStatusHistoryTable from './AnalyticalStatusHistoryTable';
import { useSnackbar } from 'notistack';
import { USER_MESSAGES } from '../../../constants/UserMessages';

let headers = [
  { label: 'Alumno/a', field: 'nameAndData' },
  { label: 'Estado', field: 'stateHtml' },
  { label: 'Año / Ciclo', field: 'yearHtml' },
  { label: 'Ciclo lectivo', field: 'schoolYear' },
  { label: 'Modificado', field: 'modifiedMoment' }
];

export const AnalyticalTable = () => {
  const { addAndAuthTableAction, isUserAdminOrLegalization } = useAuthHelper();
  const {
    count,
    page,
    limit,
    analytics,
    handlePageChange,
    patchSignAnalyticData,
    deleteAnalyticData,
    getStudentData,
    getStatusHistoryByAnalytic
  } = useContext(AnalyticalContext);
  const navigate = useNavigate();
  const { user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const [toggleSignModal, setToggleSignModal] = useState(false);
  const [toggleDeleteModal, setToggleDeleteModal] = useState(false);
  const [toggleHistoryModal, setToggleHistoryModal] = useState(false);
  const idRow = useRef(null);

  /**
   * Muestra un mensaje de advertencia cuando se intenta editar o firmar un análitico bloqueado.
   *
   * Este método es llamado automáticamente cuando se presiona el botón de edición o firma
   * y el analítico tiene `blocked_LMD = true`.
   *
   * @function
   */
  const showBlockedLmdMessage = () => {
    enqueueSnackbar(USER_MESSAGES.BLOCKED_LMD, { variant: 'warning' });
  };

  const handleClickVisibilityIcon = (e) => {
    localStorage.removeItem('action');
    if (e) {
      navigate(AnalyticRouteConstants.DETALLE(e.id), { state: { showNewCareerButton: e.showNewCareerButton } });
    }
  };

  const handleClickEditIcon = (e) => {
    localStorage.removeItem('requiredFields');
    localStorage.setItem('action', 'edit');
    if (e) {
      navigate(analyticStateValidator.getRouteToRedirectStep(e.step, e.id));
    } else {
      showBlockedLmdMessage();
    }
  };

  const handleSendObservationIcon = (e) => {
    if (e) {
      navigate(AnalyticRouteConstants.DETALLE(e.id));
    }
  };

  const handleClickNewIcon = async (e) => {
    localStorage.removeItem('action');
    if (e) {
      const studentData = await getStudentData(e.id);
      navigate(analyticStateValidator.getRouteToRedirectStep(0), {
        state: { studentData: { ...studentData, schoolYearAux: studentData.schoolYear } }
      });
    }
  };

  /**
   * Determina las propiedades de los objetos en el array que necesita `TableGenericComponent` para renderizar la data.
   * @param {*} analyticsArray
   * @returns
   */
  const calculateObjectProperties = (analyticsArray) => {
    return analyticsArray?.map((e) => {
      const singleAnalyticStateNumber = e.analytic[0].analytic_status.id;
      const showNewCareerButton = e.enableNewCareer;
      const isEstablishmentTerciary = isUserAdminOrLegalization()
        ? getStoragedBoolean('historicalEstablishmentIsTerciary') || getStoragedBoolean('analyticalEstablishmentIsTerciary')
        : user.establishments &&
          user.establishments.length > 0 &&
          user.establishments.filter((establishment) => establishment.id === user.establishmentId)[0].is_terciary;
      e.collapsible = e.analytic.length > 1;
      const studentData = (
        <>
          <Box key={e.id} sx={{ display: 'flex' }}>
            {!e.collapsible && stateHtmlAnalyticsAvatar({ stateNumber: singleAnalyticStateNumber })}
            <Box>
              <Typography variant="h6">{e.full_name}</Typography>
              <Typography variant="body2">
                {e.document_type.name} {e.document}
              </Typography>
            </Box>
          </Box>
        </>
      );
      const isParentRowCollapsible = e.collapsible;
      e.nameAndData = studentData;
      e.actions = [];
      if (isEstablishmentTerciary && showNewCareerButton && isParentRowCollapsible)
        addAndAuthTableAction(
          <TableAction
            key={e.id + 'create'}
            tooltipTitle="Nueva carrera"
            onClick={() => handleClickNewIcon(e)}
            iconElement={<AddIcon color="primary" />}
          />,
          [Actions.CREATE_ANALYTIC],
          e.actions
        );
      e.analytic = e.analytic.map((analytic) => {
        const analyticState = { stateNumber: analytic.analytic_status.id, state: analytic.analytic_status.name };
        const studyPlanData = (
          <>
            <Box key={e.id} sx={{ display: 'flex' }}>
              {stateHtmlAnalyticsAvatar({ stateNumber: analyticState.stateNumber })}
              <Box>
                <Typography variant="body1">{analytic.study_plan && analytic.study_plan.study_plan_name}</Typography>
              </Box>
            </Box>
          </>
        );
        const modified = analytic.updated_at ? analytic.updated_at : analytic.created_at ? analytic.created_at : '';
        let time = moment(modified);
        analytic.modifiedMoment = time.format('DD/MM/YYYY');
        analytic.stateHtml = <Box>{stateHtmlAnalytics(analyticState)}</Box>;
        analytic.yearHtml = e.years;
        analytic.schoolYear = e.schoolYear;
        analytic.actions = [];
        analytic.nameAndData = !isParentRowCollapsible ? studentData : studyPlanData;
        analytic.showNewCareerButton = showNewCareerButton;
        addAndAuthTableAction(
          <TableAction
            key={analytic.id + 'view'}
            tooltipTitle="Ver Detalle"
            onClick={() => handleClickVisibilityIcon(analytic)}
            iconElement={<VisibilityIcon color="primary" />}
          />,
          [Actions.READ_ANALYTIC],
          analytic.actions
        );
        if (analyticStateValidator.isValidToEdit(analytic.analytic_status.id))
          addAndAuthTableAction(
            <TableAction
              key={analytic.id + 'edit'}
              tooltipTitle="Editar"
              onClick={() => (analytic.blocked_LMD ? handleClickEditIcon() : handleClickEditIcon(analytic))}
              iconElement={<Edit color={analytic.blocked_LMD ? 'gray' : 'primary'} />}
            />,
            [Actions.UPDATE_ANALYTIC],
            analytic.actions
          );
        if (analyticStateValidator.isValidToSign(analytic.analytic_status.id))
          addAndAuthTableAction(
            <TableAction
              key={analytic.id + 'sign'}
              tooltipTitle={analytic.cuits.includes(user.cuit) ? '' : 'Firmar'}
              onClick={() => (analytic.blocked_LMD ? handleClickSign() : handleClickSign(analytic.id))}
              disabled={analytic.cuits.includes(user.cuit)}
              iconElement={<BorderColorIcon color={analytic.cuits.includes(user.cuit) || analytic.blocked_LMD ? '#808080' : 'primary'} />}
            />,
            [Actions.SIGN_ANALYTIC],
            analytic.actions
          );
        if (analytic.is_sentObservation === false) {
          if (analyticStateValidator.isValidToObservate(analytic.analytic_status.id)) {
            addAndAuthTableAction(
              <TableAction
                key={analytic.id + 'obs'}
                tooltipTitle="Revisar Observacion Pendiente"
                onClick={() => handleSendObservationIcon(analytic)}
                iconElement={<Send color="primary" />}
              />,
              // [Actions.OBSERVE_STUDYPLAN],
              [Actions.OBSERVATE_ANALYTIC],
              analytic.actions
            );
          }
        }
        const keepHidden = false; // eliminar en cuanto el cliente lo diga y volver la condición de abajo a su estado comentado
        if (/* analytic.validToDelete && !e.is_from_api */ keepHidden) {
          addAndAuthTableAction(
            <TableAction
              key={analytic.id + 'del'}
              tooltipTitle="Eliminar alumno"
              onClick={() => handleDeleteIcon(analytic.id)}
              iconElement={<DeleteIcon color="primary" />}
            />,
            [Actions.CREATE_ANALYTIC],
            analytic.actions
          );
        }
        const isAuthorizedUser = user.roles && !user.roles.includes(Roles.LEGALIZATION);
        if (isEstablishmentTerciary && showNewCareerButton && isAuthorizedUser && !isParentRowCollapsible)
          addAndAuthTableAction(
            <TableAction
              key={analytic.id + 'create'}
              tooltipTitle="Nueva carrera"
              onClick={() => handleClickNewIcon(e)}
              iconElement={<AddIcon color="primary" />}
            />,
            [Actions.CREATE_ANALYTIC],
            analytic.actions
          );
        if (analytic.analytic_status.id === 9)
          addAndAuthTableAction(
            <TableAction
              key={analytic.id + 'history'}
              tooltipTitle="Consultar estado Nación"
              onClick={() => handleOpenHistoryModal(analytic.id)}
              iconElement={<HistoryIcon color="primary" />}
            />,
            [Actions.READ_NATION_STATES],
            analytic.actions
          );
        return analytic;
      });
      return e;
    });
  };

  const analyticsHtml = calculateObjectProperties(analytics);

  const handleOpenHistoryModal = async (analyticalId) => {
    getStatusHistoryByAnalytic(analyticalId);
    setToggleHistoryModal(true);
  };

  const historyModalActions = (
    <>
      <GreyButton text="Cerrar" action={(e) => e && setToggleHistoryModal(false)} />
    </>
  );

  const handleClickSign = (e) => {
    if (e) {
      idRow.current = e;
      setToggleSignModal(true);
    } else {
      showBlockedLmdMessage();
    }
  };

  const signModalActions = (
    <>
      <GreyButton text="Cancelar" action={(e) => e && setToggleSignModal(false)} />
      <CustomYellowButton title="Firmar" action={(e) => confirmSignAnalytic(e)} />
    </>
  );

  const confirmSignAnalytic = () => {
    patchSignAnalyticData(idRow.current);
    setToggleSignModal(false);
    idRow.current = null;
  };

  const handleDeleteIcon = (id) => {
    idRow.current = id;
    setToggleDeleteModal(true);
  };

  const deleteModalActions = (
    <>
      <GreyButton text="Cancelar" action={(e) => e && setToggleDeleteModal(false)} />
      <CustomYellowButton title="Eliminar" action={() => confirmDeleteAnalytic()} />
    </>
  );

  const confirmDeleteAnalytic = () => {
    deleteAnalyticData(idRow.current);
    setToggleDeleteModal(false);
    idRow.current = null;
  };
  return (
    <>
      {toggleSignModal && (
        <Modal
          title="Firmar"
          body={
            <Typography>
              ¿Estas seguro de <b>firmar</b> el analítico?
            </Typography>
          }
          actions={signModalActions}
        />
      )}
      {toggleDeleteModal && (
        <Modal
          title="Eliminar"
          body={
            <Typography>
              ¿Estás seguro/a? Si <b>eliminás</b> al alumno/a no vas a poder volver a agregarlo.
            </Typography>
          }
          actions={deleteModalActions}
        />
      )}
      {toggleHistoryModal && (
        <Modal title="Historial de estados Nación" body={<AnalyticalStatusHistoryTable />} actions={historyModalActions} />
      )}
      <TableGenericComponent
        displayPagination={true}
        bar={false}
        columns={headers}
        rows={analyticsHtml}
        count={count}
        page={page}
        rowsPerPage={limit}
        handleChange={handlePageChange}
        hasActions={true}
        allowCollapsibleRows={true}
        message="No hay estudiantes para mostrar."
      />
      <br />
    </>
  );
};
