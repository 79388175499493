import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  getAllDocumentType,
  getStudentByAnalyticalId,
  getStudentData,
  getStudentStatus,
  patchStudentStepOne,
  postCreateNewCarreer,
  postCreateStudent
} from '../../../services/AnalyticalServices';
import { AppContext } from '../../global/AppContext';
import { AnalyticalStepOneContext } from '../AnalyticalContext';
import { useAuthHelper } from '../../../hooks/useAuthHelper';

export const DIGITAL = 'digital';
export const isAnalyticFromLMD = (matrix_number) => matrix_number === DIGITAL;

export const AnalyticalStepOneState = ({ children }) => {
  const { user } = useContext(AppContext);
  const [stepOneInfo, setStepOneInfo] = useState();
  const [options, setOptions] = useState({});
  const [analyticId, setAnalyticId] = useState('');
  const [studentId, setStudentId] = useState();
  const [studentStatus, setStudentStatus] = useState(null);
  const [isRequiredDataMissing, setIsRequiredDataMissing] = useState(false);
  const [isFormEditable, setIsFormEditable] = useState(false);
  const [existsInHistoryModule, setExistsInHistoryModule] = useState(false);
  const [isDataFromRenaper, setIsDataFromRenaper] = useState(false);
  const { isUserAdminOrLegalization } = useAuthHelper();
  let params = useParams();
  let location = useLocation();
  let newCareer = false;
  let blockExistsInAnalyticModule = false;
  const isUserAdminOrLegalizacion = isUserAdminOrLegalization();
  const establishmentId = isUserAdminOrLegalizacion ? localStorage.getItem('analyticalEstablishmentId') : user.establishmentId;
  const [studentIsInOtherEstablishment, setStudentIsInOtherEstablishment] = useState(false);

  useEffect(() => {
    getAllDocumentTypeData();
    const isForNewAnalytic = !params?.id;
    if (!isForNewAnalytic) {
      getStudentByAnalyticalIdData(params?.id);
    } else if (location.state) {
      if (location.state.studentData) setStudentDataForAdditionalAnalytic(location.state.studentData);
    }
  }, []);

  const getStudentByAnalyticalIdData = async (id) => {
    const res = await getStudentByAnalyticalId(id);
    const data = res.analytic_student;
    data.schoolYearSystem = res?.schoolYearSystem;
    data.analytic_status_id = res.analytic_status_id;
    data.override = res.override;
    data.isFromLMD = isAnalyticFromLMD(res.matrix_number);
    parseData(data);
    setStepOneInfo(data);
    setAnalyticId(id);
    setStudentId(data.student_id);
  };

  const setStudentDataForAdditionalAnalytic = (studentData) => {
    parseData(studentData);
    setStepOneInfo(studentData);
  };

  const getStudentAnalyticData = async (studentId) => {
    const data = await getStudentData(studentId);
    setStudentDataForAdditionalAnalytic(data);
  };

  const checkRequiredData = (analyticStudent) => {
    if (
      !analyticStudent.document_type_id ||
      !analyticStudent.document_number ||
      !analyticStudent.gender_id ||
      !analyticStudent.last_name ||
      !analyticStudent.name ||
      !analyticStudent.birth_date ||
      !analyticStudent.birthplace ||
      !analyticStudent.schoolYear ||
      !analyticStudent.years
    ) {
      setIsRequiredDataMissing(true);
    }
    return;
  };

  const parseData = (studentData, notEditable, notOverrideEditable) => {
    checkRequiredData(studentData);
    studentData.document_type_id = studentData.document_type ? studentData.document_type.id : studentData.document_type_id;
    studentData.student_id = parseInt(studentData.id);
    studentData.not_editable = notEditable;
    studentData.not_override_editable = notOverrideEditable;
    studentData.birth_date = studentData.birth_date ? moment(studentData.birth_date) : null;
    studentData.gender_id = studentData.genders ? studentData.genders.gender_id : studentData.gender_id;
    studentData.is_additional_analytic = Boolean(location.state);
    studentData.new_carreer_editable = studentData.isEditable;
    studentData.statusCarrera = newCareer;
    studentData.blockExistsInAnalyticModule = blockExistsInAnalyticModule;
  };

  const getStatus = async (document_type, document, gender_id, id) => {
    setIsDataFromRenaper(false);
    if (!document_type || !document || !gender_id) {
      return;
    }

    const status = await getStudentStatus(document_type, document, gender_id, establishmentId, analyticId ? analyticId : id);
    // Se reinicia el estado antes de volver a settearlo:
    setStudentIsInOtherEstablishment(false);
    setStudentStatus(generateStatus(status));
    setIsFormEditable(status.isEditable);
    setExistsInHistoryModule(status.existsInHistoryModule);

    if (status.newCareer && status.studentExists && localStorage.getItem('action') != 'edit') {
      newCareer = true;
      getStudentAnalyticData(status.studentId);
    }

    if (status.renaper_student) {
      setIsDataFromRenaper(true);
      const stepOneUpdated = { ...stepOneInfo };
      stepOneUpdated.document_type_id = document_type;
      stepOneUpdated.document_number = document;
      stepOneUpdated.gender_id = gender_id;
      copyRenaperInfo(status.renaper_student, stepOneUpdated);
    }
  };

  const copyRenaperInfo = (renaperStudent, stepOneUpdated) => {
    stepOneUpdated.name = renaperStudent.name;
    stepOneUpdated.last_name = renaperStudent.last_name;
    stepOneUpdated.birth_date = moment(renaperStudent.birth_date);
    setStepOneInfo(stepOneUpdated);
  };

  const generateStatus = (status) => {
    const isTerciaryEstablishment = status.newCareer;
    if (status.existsInOtherEstablishment) setStudentIsInOtherEstablishment(true);
    if (status.existsInOtherEstablishment && !isTerciaryEstablishment) {
      return status.OtherEstablishmentLabelMessage;
    }
    if (status.existsInAnalyticModule && localStorage.getItem('action') === 'headerNewCareer') {
      blockExistsInAnalyticModule = true;
      const phraseEnd = isTerciaryEstablishment
        ? ", puede crear un nuevo analítico para este estudiante desde la opción 'Nueva Carrera'."
        : '.';
      return !location.state ? `El/la alumno/a ya se encuentra en el listado de alumnos del módulo analítico${phraseEnd}` : null;
    }
    if (status.existsInHistoryModule) {
      if (isTerciaryEstablishment) {
        return 'Para editar los datos personales de el/la alumno/a primero debe anular todos los analíticos legalizados que posea.';
      } else {
        return 'El/la alumno/a ya se encuentra en el módulo histórico.';
      }
    }
    if (status.existsInMiEscuela) {
      return `No es posible ingresar un nuevo alumno con estos datos porque el alumno ${stepOneInfo.name.toUpperCase()} ${stepOneInfo.last_name.toUpperCase()} ya existe en miEscuela.`;
    }
  };

  const getAllDocumentTypeData = async () => {
    let res = await getAllDocumentType();
    const genders = [
      { id: 'F', name: 'F' },
      { id: 'M', name: 'M' },
      { id: 'X', name: 'X' }
    ];
    setOptions({ documentTypes: res, genders: genders });
  };

  const patchUpdateAnalyticalData = async (formData) => {
    formData.student_id = parseInt(studentId);
    formData.analyticalId = parseInt(analyticId);
    await patchStudentStepOne(formData);
    formData.birth_date = moment(formData.birth_date);
    setStepOneInfo(formData);
  };

  const postNewAnalyticalData = async (formData) => {
    let request = { ...formData, establishmentId };
    let res = await postCreateStudent(request);
    setAnalyticId(res.analyticId);
    setStudentId(res.studentId);
    return res.analyticId;
  };

  const postAdditionalAnalyticalData = async (formData, hasModifications) => {
    let request = { ...formData, establishmentId, wasEdited: hasModifications };
    let res = await postCreateNewCarreer(request);
    setAnalyticId(res.analyticId);
    setStudentId(res.studentId);
    return res.analyticId;
  };

  return (
    <AnalyticalStepOneContext.Provider
      value={{
        stepOneInfo: stepOneInfo,
        analyticId: analyticId,
        options: options,
        studentStatus,
        isRequiredDataMissing,
        patchUpdateAnalyticalData,
        postNewAnalyticalData,
        postAdditionalAnalyticalData,
        getStatus,
        isFormEditable,
        studentId,
        studentIsInOtherEstablishment,
        setStudentStatus,
        setStepOneInfo,
        existsInHistoryModule,
        isDataFromRenaper
      }}>
      {children}
    </AnalyticalStepOneContext.Provider>
  );
};
