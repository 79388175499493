import { Breadcrumbs, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const CustomBreadcrumbs = ({ base, now, baseLink = '/', onClick }) => {
  const navigate = useNavigate();
  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" separator="›">
        <Link underline="hover" color="primary" href={baseLink}>
          {base}
        </Link>
        <Link
          underline="hover"
          color="inherit"
          onClick={() => {
            navigate({});
            onClick();
          }}>
          {now}
        </Link>
      </Breadcrumbs>
      <br />
    </>
  );
};
