import { Alert, Box, FormControl, Grid, TextField, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CustomModal } from '../../../components/CustomModal';
import { CustomYellowButton } from '../../../components/CustomYellowButton';
import { GreyButton } from '../../../components/GreyButton';
import { Modal } from '../../../components/Modal';
import { Actions, Roles } from '../../../constants/Actions';
import { LEGALIZADO, OBSERVADO, PENDIENTE_DE_VALIDACION } from '../../../constants/AnalyticalStates';
import { AnalyticalDetailContext } from '../../../context/Analytical/AnalyticalContext';
import { AppContext } from '../../../context/global/AppContext';
import { analyticStateValidator } from '../../../helpers/analyticStateValidator';
import { useAuthHelper } from '../../../hooks/useAuthHelper';
import { AnalyticRouteConstants, HistoricalRouteConstants } from '../../../routes/RoutesConstants';
import { CancelLegalizedAnalyticModal } from '../../historical/search/CancelLegalizedAnalyticModal';
import { UnsubscribeAnalyticModal } from '../../historical/search/UnsubscribeAnalyticModal';
import { alphaNumValidation } from '../../../helpers/functionValidatorHelper';
import { getStoragedBoolean } from '../../../helpers/getStoragedBoolean';
import { USER_MESSAGES } from '../../../constants/UserMessages';
import { createAlert } from '../../../tools/alerts';

export const AnalyticalDetailHeader = () => {
  const {
    postAnalyticNote,
    studentSelectedTable,
    patchAnalyticNote,
    deleteAnalyticNote,
    patchSignAnalyticData,
    patchValidateAnalyticData,
    infoAnaliticData,
    unsubscribe,
    handleCancelAnalytic,
    getStudentStepOneData
  } = useContext(AnalyticalDetailContext);
  const { user } = useContext(AppContext);
  const [toggleSignModal, setToggleSignModal] = useState(false);
  const { authorizeElement, isUserAdminOrLegalization } = useAuthHelper();
  const [toggleValidModal, setToggleValidModal] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const showNewCareerButton = location.state ? location.state.showNewCareerButton : null;
  const isEditionBlockedByLMD = infoAnaliticData?.step1.blocked_LMD;

  const [value, setValues] = React.useState('');
  const [toggleModal, setToggleModal] = React.useState(false);
  const [toggleConfirmModal, setToggleConfirmModal] = React.useState(false);
  const [unsubscribeModalIsOpen, setUnsubscribeModalIsOpen] = useState(false);
  const [cancelLegalizedAnalyticIsOpen, setCancelLegalizedAnalyticIsOpen] = useState(false);
  const handleChange = (event) => {
    setValues(event.target.value);
  };

  let cuits = [];

  if (infoAnaliticData?.firm && infoAnaliticData?.firm.length) {
    const [{ analytic_firms }] = infoAnaliticData.firm;
    analytic_firms.map((signatories) => cuits.push(signatories.user[0].user.cuit));
  }

  const lastObservation = studentSelectedTable?.observations?.analyticObservations.at(-1);

  const onClickToggleModal = () => {
    if (!value && !lastObservation?.is_sent) setValues(lastObservation ? lastObservation.descriptions : '');
    setToggleModal(!toggleModal);
  };

  const onCancelEditionObservation = () => {
    setToggleModal(false);
    setValues('');
  };

  const onClickToggleModalConfirm = () => {
    setToggleConfirmModal(!toggleConfirmModal);
    setToggleModal(!toggleModal);
  };

  const handleCreateNewAnalytic = async () => {
    const stepOneData = await getStudentStepOneData();
    if (stepOneData) {
      navigate(analyticStateValidator.getRouteToRedirectStep(0), {
        state: { studentData: { ...stepOneData, schoolYear: stepOneData.schoolYear, years: stepOneData.years } }
      });
    }
  };

  const handleEdit = () => {
    if (studentSelectedTable.step > 2) {
      navigate(analyticStateValidator.getRouteToRedirectStep(studentSelectedTable.step - 1, id));
    } else {
      navigate(analyticStateValidator.getRouteToRedirectStep(studentSelectedTable.step, id));
    }
  };

  const signModalActions = (
    <>
      <GreyButton text="Cancelar" action={(e) => e && setToggleSignModal(false)} />
      <CustomYellowButton title="Firmar" action={(e) => confirmSignAnalytic(e)} />
    </>
  );
  const confirmSignAnalytic = (e) => {
    if (e) {
      patchSignAnalyticData(id);
      setToggleSignModal(false);
    }
  };

  const validModalActions = (
    <>
      <GreyButton text="Cancelar" action={(e) => e && setToggleValidModal(false)} />
      <CustomYellowButton title="Validar" action={(e) => confirmValidateAnalytic(e)} />
    </>
  );
  const confirmValidateAnalytic = (e) => {
    if (e) {
      patchValidateAnalyticData(id);
      setToggleValidModal(false);
    }
  };

  const modalBody = (
    <>
      <FormControl required>
        <Grid container>
          <Typography>Observaciones *</Typography>
          <Typography style={{ marginLeft: 'auto' }}>{`${value.length}/250`}</Typography>
        </Grid>
        <TextField
          id="text-area"
          aria-label="Observaciones"
          placeholder="Escribir..."
          inputProps={{
            maxLength: 250
          }}
          value={value}
          onChange={(e) => handleChange(e)}
          margin="normal"
          variant="outlined"
          multiline
          fullWidth
          style={{ width: 435 }}
        />
      </FormControl>
    </>
  );

  const isFieldInvalid = !value || (value && !alphaNumValidation(value).isValid);
  const isEstablishmentTerciary = isUserAdminOrLegalization()
    ? getStoragedBoolean('historicalEstablishmentIsTerciary') || getStoragedBoolean('analyticalEstablishmentIsTerciary')
    : user.establishments.filter((establishment) => establishment.id === user.establishmentId)[0].is_terciary;
  const modalActions = (
    <>
      <GreyButton text="Cancelar" action={onCancelEditionObservation} />
      <Grid container style={{ justifyContent: 'flex-end' }}>
        <GreyButton text="Guardar" action={(e) => onClickSaveNote(e)} disabled={isFieldInvalid} />
        <CustomYellowButton title="Enviar" action={(e) => onClickSendNote(e)} disabled={isFieldInvalid} />
      </Grid>
    </>
  );

  const confirmModalBody = (
    <>
      <Typography>
        ¿Estas seguro de <b>enviar las observaciones</b> del analítico?
      </Typography>
    </>
  );
  const confirmModalActions = (
    <>
      <Grid container style={{ justifyContent: 'flex-end' }}>
        <GreyButton text="Cancelar" action={onClickToggleModalConfirm} />
        <CustomYellowButton title="Enviar" action={(e) => onClickConfirmSendNote(e)} />
      </Grid>
    </>
  );
  const onClickSendNote = () => {
    onClickToggleModal();
    setToggleConfirmModal(true);
  };

  const onClickConfirmSendNote = async () => {
    //si mi ultima observacion esta pendiente de envio hago un put, si no un post
    await saveOrUpdateObservationNote(true);
    setToggleConfirmModal(false);
    setValues('');
  };

  const saveOrUpdateObservationNote = async (confirm) => {
    if (lastObservation?.is_sent === false) {
      let body = {
        id_analytic: studentSelectedTable.id,
        descriptions: value ? value : lastObservation.descriptions,
        is_sent: confirm,
        id_observation: lastObservation.id
      };
      await patchAnalyticNote(body);
    } else {
      let body = {
        id_analytic: studentSelectedTable.id,
        descriptions: value,
        is_sent: confirm
      };
      await postAnalyticNote(body);
    }
  };

  const onClickSaveNote = async () => {
    await saveOrUpdateObservationNote(false);
    onClickToggleModal();
    setValues('');
  };

  const onClickDeleteNote = () => {
    if (lastObservation?.is_sent === false) {
      let body = {
        id_observation: lastObservation.id
      };
      deleteAnalyticNote(body);
    }
  };

  const onUnsubscribe = async () => {
    await unsubscribe({ id: parseInt(id) });
    setUnsubscribeModalIsOpen(false);
    // Se determinan el ID y el NAME en función de que se haya navegado desde HISTÓRICOS:
    const establishmentId = localStorage.getItem('historicalEstablishmentId');
    const establishmentName = localStorage.getItem('historicalEstablishmentName');
    localStorage.setItem('remoteUnsubscribeAlert', true);
    if (establishmentId) {
      navigate(
        isUserAdminOrLegalization()
          ? HistoricalRouteConstants.BUSCAR_PARAMS(establishmentId, establishmentName)
          : HistoricalRouteConstants.BUSCAR
      );
    }
  };

  const onCancel = async (request) => {
    await handleCancelAnalytic(request);
    setCancelLegalizedAnalyticIsOpen(false);
  };

  const hasPendingObservation = () => {
    return lastObservation?.is_sent == false;
  };

  return (
    <>
      <Grid container justifyContent="space-between" sx={{ mb: 2 }}>
        {studentSelectedTable && (
          <Grid container>
            <Grid item xs={5}>
              <Typography variant="h4">Detalle de analítico</Typography>
            </Grid>
            <Grid item xs={7}>
              <Grid container direction="row" justifyContent="flex-end" alignItems="baseline">
                {isEstablishmentTerciary &&
                  showNewCareerButton &&
                  user.roles &&
                  !user.roles.includes(Roles.LEGALIZATION) &&
                  studentSelectedTable.analytic_status_id !== LEGALIZADO &&
                  authorizeElement(
                    <Grid item>
                      <CustomYellowButton title="+ Nueva Carrera" action={handleCreateNewAnalytic} />
                    </Grid>,
                    [Actions.CREATE_ANALYTIC]
                  )}
                {analyticStateValidator.isValidToValidate(studentSelectedTable.analytic_status_id) &&
                  authorizeElement(
                    <Grid item>
                      <CustomYellowButton title="Validar" action={(e) => e && setToggleValidModal(true)} />
                    </Grid>,
                    [Actions.VALIDATE_ANALYTIC]
                  )}
                {analyticStateValidator.isValidToSign(studentSelectedTable.analytic_status_id) &&
                  !isEditionBlockedByLMD &&
                  authorizeElement(
                    <Grid item sx={{ marginLeft: '16px' }}>
                      <CustomYellowButton
                        disabled={cuits.includes(user.cuit)}
                        title="Firmar"
                        action={(e) => e && setToggleSignModal(true)}
                      />
                    </Grid>,
                    [Actions.SIGN_ANALYTIC]
                  )}

                {analyticStateValidator.isValidToEdit(studentSelectedTable.analytic_status_id) &&
                  !isEditionBlockedByLMD &&
                  authorizeElement(
                    <Grid item sx={{ mr: -2 }}>
                      <GreyButton text="Editar" action={handleEdit} />
                    </Grid>,
                    [Actions.UPDATE_ANALYTIC]
                  )}
                {studentSelectedTable.analytic_status_id &&
                  !hasPendingObservation() &&
                  analyticStateValidator.isValidToObservate(studentSelectedTable.analytic_status_id) &&
                  authorizeElement(
                    <Grid item>
                      <GreyButton text="Observar" action={onClickToggleModal} />
                    </Grid>,
                    [Actions.OBSERVATE_ANALYTIC]
                  )}
                {analyticStateValidator.isValidToAnnulAnalitic(studentSelectedTable.analytic_status_id) &&
                  authorizeElement(
                    <Grid item>
                      <GreyButton text="Anular" action={(e) => e && setCancelLegalizedAnalyticIsOpen(true)} />
                    </Grid>,
                    [Actions.CANCEL_LEGALIZED_ANALYTIC]
                  )}
                {analyticStateValidator.isValidToDownAnalitic(studentSelectedTable.analytic_status_id) &&
                  authorizeElement(
                    <Grid item>
                      <GreyButton text="Dar de baja" action={(e) => e && setUnsubscribeModalIsOpen(true)} />
                    </Grid>,
                    [Actions.UNSUSCRIBE_ANALYTIC]
                  )}
                {studentSelectedTable && studentSelectedTable.nextId && studentSelectedTable.analytic_status_id !== LEGALIZADO && (
                  <Grid item sx={{ marginLeft: '16px' }}>
                    <CustomYellowButton
                      title="Siguiente"
                      action={() => {
                        navigate(AnalyticRouteConstants.DETALLE(studentSelectedTable.nextId));
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Box sx={{ mr: 5 }}>
        {toggleModal && <Modal title="Observar analítico" body={modalBody} actions={modalActions} />}
        {toggleConfirmModal && <Modal title="Enviar observaciones" body={confirmModalBody} actions={confirmModalActions} />}
        {toggleSignModal && (
          <Modal
            title="Firmar"
            body={
              <Typography>
                ¿Estas seguro de <b>firmar</b> el analítico?
              </Typography>
            }
            actions={signModalActions}
          />
        )}
        {toggleValidModal && (
          <Modal
            title="Validar analítico"
            body={
              <Typography>
                ¿Estas seguro de <b>validar</b> el analítico?
              </Typography>
            }
            actions={validModalActions}
          />
        )}
        <CustomModal open={unsubscribeModalIsOpen} handleClose={() => setUnsubscribeModalIsOpen(false)}>
          <UnsubscribeAnalyticModal handleUnsubscribed={onUnsubscribe} handleClose={() => setUnsubscribeModalIsOpen(false)} />
        </CustomModal>
        <CustomModal open={cancelLegalizedAnalyticIsOpen} handleClose={() => setCancelLegalizedAnalyticIsOpen(false)}>
          <CancelLegalizedAnalyticModal
            useAnalyticContext={true}
            id={id}
            handleCancel={onCancel}
            handleClose={() => setCancelLegalizedAnalyticIsOpen(false)}
          />
        </CustomModal>
        {lastObservation
          ? hasPendingObservation() && studentSelectedTable.analytic_status_id === PENDIENTE_DE_VALIDACION
            ? (user.roles.includes(Roles.SUPERADMIN) ||
                user.roles.includes(Roles.ADMIN) ||
                user.roles.includes(Roles.LEGALIZATION) ||
                user.roles.includes(Roles.VALIDADOR)) && (
                <Alert
                  variant="outlined"
                  severity="warning"
                  sx={{
                    mt: 4,
                    width: '100%',
                    fontSize: 16,
                    borderLeft: '8px solid #ff9800',
                    mb: 2
                  }}
                  action={
                    <Grid
                      container
                      sx={(theme) => ({
                        [theme.breakpoints.down('lg')]: { flexDirection: 'column', alignItems: 'flex-end' },
                        marginLeft: 'auto',
                        minWidth: 'max-content'
                      })}>
                      <Grid
                        item
                        sx={(theme) => ({
                          [theme.breakpoints.down('lg')]: { margin: '10px 8px 10px 0' }
                        })}>
                        <CustomYellowButton title="Enviar" action={(e) => onClickConfirmSendNote(e)} />
                      </Grid>
                      <Grid
                        item
                        sx={(theme) => ({
                          [theme.breakpoints.down('lg')]: { margin: '0 -10px 0 0' }
                        })}>
                        <GreyButton text="Editar" action={onClickToggleModal} />
                      </Grid>
                      <Grid
                        item
                        sx={(theme) => ({
                          [theme.breakpoints.down('lg')]: { margin: '10px -10px 0 0' },
                          ml: '-16px'
                        })}>
                        <GreyButton text="Eliminar" action={onClickDeleteNote} />
                      </Grid>
                    </Grid>
                  }>
                  <b>Analítico observado</b>
                  <br />
                  {lastObservation?.descriptions}
                </Alert>
              )
            : studentSelectedTable.analytic_status_id === OBSERVADO &&
              createAlert('Analítico observado', lastObservation?.descriptions, 'warning')
          : undefined}
        {isEditionBlockedByLMD ? createAlert('Este analítico no puede editarse ni firmarse', USER_MESSAGES.BLOCKED_LMD, 'warning') : null}
      </Box>
    </>
  );
};
