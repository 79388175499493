import { Grid } from "@mui/material";
import { Observed } from "../styles/Styles";

const CanceledAnalyticTag = () => {
  return (
    <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
      <Observed>ANALÍTICO PREVIAMENTE ANULADO</Observed>
    </Grid>
  );
};

export default CanceledAnalyticTag;
