import { Alert, FormControl, Grid, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomYellowButton } from '../../../components/CustomYellowButton';
import { GreyButton } from '../../../components/GreyButton';
import { Modal } from '../../../components/Modal';
import { ObservationDetailAlert } from '../../../components/ObservationDetailAlert';
import { Actions } from '../../../constants/Actions';
import { CurriculumContext } from '../../../context/Curriculum/CurriculumContext';
import { curriculumStateValidator } from '../../../helpers/curriculumStateValidator';
import { useAuthHelper } from '../../../hooks/useAuthHelper';
import { StudyPlanRouteConstants } from '../../../routes/RoutesConstants';
import { alphaNumValidation } from '../../../helpers/functionValidatorHelper';

export const CurriculumDetailHeader = ({ curriculum }) => {
  const { patchCurriculumNote, postCurriculumNote, deleteCurriculumNote } = useContext(CurriculumContext);
  const { authorizeElement, isUserLegalizacion, isUserValidator } = useAuthHelper();
  const navigate = useNavigate();

  const { id } = useParams();
  const onClickEditCurriculum = () => {
    navigate(StudyPlanRouteConstants.PASO2(id));
  };

  const [value, setValues] = React.useState('');
  const handleChange = (event) => {
    setValues(event.target.value);
  };

  const [toggleValidModal, setToggleValidModal] = useState(false);

  const [toggleModal, setToggleModal] = React.useState(false);
  const [toggleConfirmModal, setToggleConfirmModal] = React.useState(false);
  const onClickToggleModal = () => {
    setToggleModal(!toggleModal);
  };

  const onClickToggleModalConfirm = () => {
    setToggleConfirmModal(false);
    setToggleModal(false);
  };

  useEffect(() => {
    let length = curriculum.observations?.filter((x) => !x.is_sent).length || 0;
    if (curriculum.observations && length && !toggleConfirmModal) {
      setValues(curriculum.observations[length - 1].observation);
    } else {
      if (!toggleConfirmModal) {
        setValues('');
      }
    }
  }, [curriculum, toggleModal]);

  const modalBody = (
    <>
      <FormControl required>
        <Grid container>
          <Typography>Observaciones *</Typography>
          <Typography style={{ marginLeft: 'auto' }}>{`${value.length}/250`}</Typography>
        </Grid>
        <TextField
          id="text-area"
          aria-label="Observaciones"
          placeholder="Mensaje..."
          inputProps={{
            maxLength: 250
          }}
          value={value}
          onChange={(e) => handleChange(e)}
          margin="normal"
          variant="outlined"
          multiline
          fullWidth
          style={{ width: 435, background: '#F3F6F9' }}
        />
      </FormControl>
    </>
  );

  const isFieldInvalid = !value || (value && !alphaNumValidation(value).isValid);

  const modalActions = (
    <>
      <GreyButton text="Cancelar" action={onClickToggleModal} />
      <Grid container style={{ justifyContent: 'flex-end' }}>
        <GreyButton text="Guardar" action={() => onClickSaveNote()} disabled={isFieldInvalid} />
        <CustomYellowButton title="Enviar" action={() => onClickSendNote()} disabled={isFieldInvalid} />
      </Grid>
    </>
  );

  const confirmModalBody = (
    <>
      <Typography>
        ¿Estás seguro de <b>enviar las observaciones</b> del plan de estudios?
      </Typography>
    </>
  );
  const confirmModalActions = (
    <>
      <Grid container style={{ justifyContent: 'flex-end' }}>
        <GreyButton text="Cancelar" action={onClickToggleModalConfirm} />
        <CustomYellowButton title="Enviar" action={(e) => onClickConfirmSendNote(e)} />
      </Grid>
    </>
  );
  const onClickSendNote = () => {
    onClickToggleModal();
    setToggleConfirmModal(true);
  };

  const onClickConfirmSendNote = () => {
    let body = {
      studyPlanId: curriculum.id,
      studyPlanStatusId: 2,
      observation: value,
      observation_id: curriculum.observations[curriculum.observations.length - 1]
        ? curriculum.observations[curriculum.observations.length - 1].id
        : null
    };
    patchCurriculumNote(body);
    setToggleConfirmModal(false);
    setValues('');
  };

  const onClickSaveNote = () => {
    if (curriculum) {
      let body = {
        studyPlanId: curriculum.id,
        observation_id: curriculum.observations[curriculum.observations.length - 1]
          ? curriculum.observations[curriculum.observations.length - 1].id
          : null,
        observation: value
      };
      postCurriculumNote(body);
      onClickToggleModal();
      setValues('');
    }
  };

  const onClickDeleteNote = () => {
    deleteCurriculumNote(curriculum.observations[curriculum.observations.length - 1].id);
  };

  const confirmAprobeModalActions = (
    <>
      <GreyButton text="Cancelar" action={(e) => e && setToggleValidModal(false)} />
      <CustomYellowButton title="Aprobar" action={(e) => confirmAprobeStudyPlan(e)} />
    </>
  );

  const confirmAprobeStudyPlan = async (e) => {
    if (e) {
      let body = {
        studyPlanId: curriculum.id,
        studyPlanStatusId: 3
      };
      await patchCurriculumNote(body);
      setToggleValidModal(false);
      navigate(StudyPlanRouteConstants.BUSCAR);
    }
  };

  return (
    <Grid container justifyContent={'space-between'} alignItems={'center'}>
      {curriculum && (
        <>
          <Grid container>
            <Grid item xs={5}>
              <Typography sx={{ fontSize: '37px', fontWeight: '700', lineHeight: '44px' }}>Detalle del Plan de estudios</Typography>
            </Grid>
            <Grid item xs={7} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline' }}>
              {curriculumStateValidator.validToObserve(curriculum.study_plan_status_id) &&
                (!curriculum.observations || curriculum.observations.filter((x) => !x.is_sent).length === 0) &&
                authorizeElement(<GreyButton text="Observar" action={onClickToggleModal} />, [Actions.OBSERVE_STUDYPLAN])}

              {curriculumStateValidator.validToEdit(curriculum.study_plan_status_id) &&
                authorizeElement(<GreyButton text="Editar" action={onClickEditCurriculum} />, [Actions.UPDATE_STUDYPLAN])}

              {curriculumStateValidator.validToAprove(curriculum.study_plan_status_id) &&
                authorizeElement(<CustomYellowButton title="Aprobar" action={(e) => e && setToggleValidModal(true)} />, [
                  Actions.APPROVE_STUDYPLAN
                ])}
              {curriculum.nextStudyPlanId && (isUserLegalizacion() || isUserValidator()) ? (
                <CustomYellowButton
                  title="Siguiente"
                  action={() => {
                    navigate(StudyPlanRouteConstants.DETALLE(curriculum.nextStudyPlanId));
                  }}
                  style={{ marginLeft: 16, marginRight: 16, marginTop: 0 }}
                />
              ) : null}
            </Grid>
          </Grid>
          {toggleValidModal && (
            <Modal
              title="Aprobar plan de estudios"
              body={
                <Typography>
                  ¿Estás seguro de <b>aprobar</b> el plan de estudios?
                </Typography>
              }
              actions={confirmAprobeModalActions}
            />
          )}
          {toggleModal && <Modal title="Observar plan de estudios" body={modalBody} actions={modalActions} />}
          {toggleConfirmModal && <Modal title="Enviar observaciones" body={confirmModalBody} actions={confirmModalActions} />}
          {curriculum.observations && curriculum.observations[curriculum.observations.length - 1]?.observation ? (
            curriculum.observations[curriculum.observations.length - 1].is_sent === false ? (
              <Alert
                variant="outlined"
                severity="warning"
                sx={{ mt: 4, width: '100%', fontSize: 16, borderLeft: '8px solid #ff9800', '& .MuiAlert-message': { maxWidth: '50%' } }}
                action={
                  <Grid
                    container
                    sx={(theme) => ({
                      [theme.breakpoints.down('lg')]: { flexDirection: 'column', alignItems: 'flex-end' },
                      marginLeft: 'auto',
                      minWidth: 'max-content'
                    })}>
                    {2 !== curriculum.study_plan_status_id ? (
                      <>
                        <Grid
                          item
                          sx={(theme) => ({
                            [theme.breakpoints.down('lg')]: { margin: '10px 8px 10px 0' }
                          })}>
                          {authorizeElement(
                            <CustomYellowButton title="Enviar" action={() => setToggleConfirmModal(!toggleConfirmModal)} />,
                            [Actions.OBSERVE_STUDYPLAN]
                          )}
                        </Grid>
                        <Grid
                          item
                          sx={(theme) => ({
                            [theme.breakpoints.down('lg')]: { margin: '0 -10px 0 0' }
                          })}>
                          {authorizeElement(<GreyButton text="Editar" action={onClickToggleModal} />, [Actions.OBSERVE_STUDYPLAN])}
                        </Grid>
                        <Grid
                          item
                          sx={(theme) => ({
                            [theme.breakpoints.down('lg')]: { margin: '10px -10px 0 0' },
                            ml: '-16px'
                          })}>
                          {authorizeElement(<GreyButton text="Eliminar" action={onClickDeleteNote} />, [Actions.OBSERVE_STUDYPLAN])}
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                }>
                <b>Plan de estudios observado</b>
                <br />
                {curriculum.observations[curriculum.observations.length - 1].observation}
              </Alert>
            ) : (
              curriculum.study_plan_status_id == 2 && (
                <ObservationDetailAlert observationDescription={curriculum.observations[curriculum.observations.length - 1].observation} />
              )
            )
          ) : undefined}
        </>
      )}
    </Grid>
  );
};
