import { Box, Paper, Typography } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { CustomYellowButton } from '../../../components/CustomYellowButton';
import { GradeYearButton } from '../../../components/GradeYearButton';
import { GreyButton } from '../../../components/GreyButton';
import EditableTableConfig from '../../../configs/analyticalStepThreeConfig.json';
import { ANULADO, EN_PROCESO } from '../../../constants/AnalyticalStates';
import { AnalyticalStepThreeContext } from '../../../context/Analytical/AnalyticalContext';
import { CurricularBoxTable } from './CurricularBoxTable';
const { tableConfig } = EditableTableConfig;

const PARCIALES = 2;
export const AnalyticalStepThreeForm = () => {
  const [level, setLevel] = useState(1);
  const [isCurriculumBoxDisabled, setIsCurriculumBoxDisabled] = useState(false);
  const {
    options,
    sendDataFromTable,
    handlePrevious,
    updateInfoCurriculumBox,
    allLevelsCurriculum,
    validToFinish,
    analyticType,
    previewInfo,
    curriculumBox,
    setCurriculumBox,
    canLoadMaximumQualification
  } = useContext(AnalyticalStepThreeContext);

  useEffect(() => {
    if (previewInfo) {
      const { override, analytic_status_id } = previewInfo;
      if ((!override.editStep3 && analytic_status_id === 7) || previewInfo.analytic_student.isFromLMD) {
        setIsCurriculumBoxDisabled(true);
      }
    }
  }, [previewInfo]);

  useEffect(() => {
    updateInfoCurriculumBox(level);
  }, [level]);

  const isEdited = useMemo(() => {
    return curriculumBox.some((x) => x.edited);
  }, [curriculumBox]);

  const handleChangeLevel = (level) => {
    setLevel(level);
    sendDataFromTable(level, setLevel, curriculumBox, null, previewInfo.analytic_status_id === EN_PROCESO ? true : isEdited);
  };

  const handleSendData = (send = null) => {
    sendDataFromTable(
      level,
      setLevel,
      curriculumBox,
      send,
      previewInfo.analytic_status_id === ANULADO || previewInfo.analytic_status_id === EN_PROCESO || isEdited
    );
  };

  const getLevelLabel = (studyPlanModalityId) => {
    if (previewInfo?.study_plan?.study_plan_modality_id === studyPlanModalityId) {
      return studyPlanModalityId === 1 ? 'Año' : 'Ciclo';
    }
    return null;
  };

  const blockRow = previewInfo && previewInfo.not_editable;

  return (
    <>
      {allLevelsCurriculum && (
        <>
          <br />
          <Paper elevation={3}>
            <Box sx={{ p: 3, pb: 4 }}>
              <Typography variant="h4" color="initial">
                Caja curricular
              </Typography>
              <br />
              <Box sx={{ display: 'flex' }}>
                {allLevelsCurriculum.map((x) => (
                  <GradeYearButton
                    year={parseInt(x.level)}
                    key={x.level}
                    pendingState={!x.complete}
                    pageYearNow={level}
                    handle={handleChangeLevel}
                    label={getLevelLabel(previewInfo?.study_plan?.study_plan_modality_id)}
                  />
                ))}
              </Box>
              <br />
              <Box>
                <CurricularBoxTable
                  blockRow={isCurriculumBoxDisabled || blockRow}
                  selectOptions={options}
                  tableConfig={tableConfig}
                  rows={curriculumBox}
                  updateRows={setCurriculumBox}
                  canLoadMaximumQualification={canLoadMaximumQualification}
                />
              </Box>
            </Box>
          </Paper>
          <br />
          {/* MODIFICAR */}

          <Box sx={{ float: 'right', display: 'flex', mb: 5 }}>
            <GreyButton text="Anterior" action={() => handlePrevious(level, setLevel, curriculumBox, isEdited)} />
            <GreyButton text="Guardar" disabled={blockRow} action={() => handleSendData()} />
            {level < allLevelsCurriculum.length ? (
              <CustomYellowButton title="Siguiente" action={() => handleSendData(true)} />
            ) : validToFinish(allLevelsCurriculum) || analyticType == PARCIALES ? (
              <CustomYellowButton title="Finalizar" action={() => handleSendData(true)} />
            ) : (
              ''
            )}
          </Box>
        </>
      )}
    </>
  );
};
