const STUDENT_STATUS_CODES = Object.freeze(
  Object.fromEntries(
    [
      'EXISTS_IN_ANOTHER_ESTABLISHMENT',
      'EXISTS_IN_ANALYTIC_MODULE',
      'EXISTS_IN_HISTORY_MODULE',
      'ONLY_LEGALIZED_ANALITICS',
      'EXISTS_IN_MI_ESCUELA',
      'NEW_CARREER'
    ].map((key) => [key, key])
  )
);

export default STUDENT_STATUS_CODES;
