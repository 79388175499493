import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  DefaultEstablishmentOptionName,
  EstablishmentHeaderOptions,
  EstablishmentOptions
} from '../../../helpers/CurricularBoxEstablishmentOptions';
import { formatDecimal } from '../../../helpers/decimalHelper';
import { useTable } from '../../../hooks/useTable';
import { AnalyticRouteConstants } from '../../../routes/RoutesConstants';
import {
  getStepThreeCurriculumBox,
  getStepThreeCurriculumBoxAll,
  getStepThreeStudentConditions,
  getStepThreeStudentQualifications,
  getStudentByAnalyticalId,
  getValidateQualificationRange,
  patchStepThreeCurriculumBox
} from '../../../services/AnalyticalServices';
import { month } from '../../../tools/months';
import { AnalyticalStepThreeContext } from '../AnalyticalContext';
import { isAnalyticFromLMD } from '../StepOne/AnalyticalStepOneState';

export const AnalyticalStepThreeState = ({ children }) => {
  const [previewInfo, setPreviewInfo] = useState(null);
  const [allLevelsCurriculum, setAllLevelsCurriculum] = useState();
  const [analyticType, setAnalyticType] = useState();
  const [curriculumBox, setCurriculumBox] = useState([]);
  const [canLoadMaximumQualification, setCanLoadMaximumQualification] = useState(null);
  // Pagination
  const tableInfo = useTable(5, 0);
  const paginationProps = tableInfo.paginationProps;

  useEffect(() => {
    // Evita que la caja curricular se renderice vacía
    if (curriculumBox.length <= 0) {
      setTimeout(() => updateInfoCurriculumBox(1), 1500);
    }
  }, []);

  useEffect(() => {
    getStudentByAnalyticalIdData(params.id);
    getStepThreeCurriculumBoxAllData(params.id);
    getOptions();
  }, [analyticType]);

  let params = useParams();
  const navigate = useNavigate();

  const getStudentByAnalyticalIdData = async (id) => {
    let res = await getStudentByAnalyticalId(id);
    res.analytic_student.isFromLMD = isAnalyticFromLMD(res.matrix_number);
    setPreviewInfo(res);
    if (res?.establishment?.id) {
      let result = await getValidateQualificationRange(res?.establishment?.id);
      setCanLoadMaximumQualification(result?.canLoadMaximumQualification);
    }
  };

  //muestro el finalizar si solo falta un nivel para validar
  const validToFinish = (all) => {
    return all && all.filter((e) => e.complete).length >= all.length - 1;
  };

  const getStepThreeCurriculumBoxAllData = async (id) => {
    let res = await getStepThreeCurriculumBoxAll(id);
    let levels = [...res.levels];
    setAnalyticType(res.analytic_type_id);
    levels.levelsLength = Object.keys(res.levels).length - 1;
    setAllLevelsCurriculum(levels);
  };

  const getStepThreeCurriculumBoxData = async (id, level) => {
    let res = await getStepThreeCurriculumBox(id, level);
    let data = res.map((r) => {
      r.course = r.study_plan_curricular_box.course;
      if (r.qualification || r.numeric_qualification) {
        let qualificationName = r.qualification ? getQualificationName(r.qualification) : null;
        r.qualification_id = r.numeric_qualification ? formatDecimal(parseFloat(r.numeric_qualification)) : qualificationName;
      }
      r.establishment_name = r.is_own ? DefaultEstablishmentOptionName : r.other_establishment;
      return r;
    });
    return data;
  };

  const getQualificationName = (qualification) => {
    return !qualification.abbreviation ? qualification.qualification : qualification.abbreviation;
  };

  // Actualiza los datos de la caja curricular
  const updateInfoCurriculumBox = async (level) => {
    let res = await getStepThreeCurriculumBoxData(params.id, level);
    let resFin = res.map((e) => {
      if (e.conditions) e.conditions_id = e.conditions.id;
      if (e.establishment) e.establishment_id = e.establishment.id;
      return e;
    });
    setCurriculumBox(resFin);
    getStepThreeCurriculumBoxAllData(params.id);
  };

  // Este seria las calificaciones
  const getStepThreeStudentQualificationsData = async () => {
    let res = await getStepThreeStudentQualifications();
    let data = res.map((r) => {
      if (r.qualification != 'ADEUDA' && r.qualification != 'S/CALIFICAR' && r.qualification != 'EN PROCESO') {
        r.value = r.qualification;
        return r;
      } else if (analyticType == 2) {
        r.value = r.qualification;
        return r;
      }
    });
    return data.filter((e) => e);
  };

  // Seria la modalidad de estudio
  const getStepThreeStudentConditionsData = async () => {
    let res = await getStepThreeStudentConditions();
    let data = res.map((r) => {
      if (r.condition != 'ADEUDA') {
        r.value = r.condition;
        return r;
      } else if (analyticType == 2) {
        r.value = r.condition;
        return r;
      }
    });
    return data.filter((e) => e);
  };

  const getOptions = async () => {
    let qualifications = await getStepThreeStudentQualificationsData();
    let conditions = await getStepThreeStudentConditionsData();
    tableInfo.updateOptions({
      qualifications: qualifications.map((x) => {
        return { id: x.id, name: x.qualification };
      }),
      conditions: conditions.map((x) => {
        return { id: x.id, name: x.value };
      }),
      month: month(),
      establishments: EstablishmentOptions,
      etablishmentsHeader: EstablishmentHeaderOptions,
      years: getYearsArray()
    });
  };

  const patchStepThreeCurriculumBoxData = async (body) => {
    const request = { ...body };
    request.is_own = request.estbalsihment_name === delete body.title;
    let res = patchStepThreeCurriculumBox(body);
    return res;
  };

  // nex define si despues del patch se queda en la pagina o va a la siguiente
  const sendDataFromTable = async (level, setLevel, curriculumBox, next = false, edited) => {
    if (edited) {
      await patchCurricularBox(curriculumBox, level);
      await getStepThreeCurriculumBoxAllData(params.id);
    }
    if (next) {
      if (level < allLevelsCurriculum?.length) {
        setLevel(level + 1);
      } else {
        navigate(AnalyticRouteConstants.DETALLE(params.id));
      }
    }
  };

  const patchCurricularBox = async (curriculumBox, level) => {
    let res = { curricular_box: {} };
    let array = [];
    const CONDITIONS_ADEUDA = 5;
    // const QUALIFICATIONS_ADEUDA = 13
    curriculumBox.map((e) => {
      // curricular_box
      let obj = {};
      obj.id = e.id;
      obj.analyticId = Number(params.id);
      obj.year = e.conditions_id === CONDITIONS_ADEUDA ? null : e.year;
      obj.month = e.conditions_id === CONDITIONS_ADEUDA ? null : e.month;
      obj.is_own = e.establishment_name === DefaultEstablishmentOptionName;
      if (!obj.is_own) obj.other_establishment = e.establishment_name;
      assignQualificationId(obj, e);
      obj.condition_id = e.conditions_id;
      obj.study_plan_curricular_box_id = !isNaN(e.study_plan_curricular_box.id) ? e.study_plan_curricular_box.id : null;
      obj.level = level;
      obj.studyPlanId = !isNaN(e.study_plan_curricular_box.study_plan_id) ? e.study_plan_curricular_box.study_plan_id : null;
      array = [...array, obj];
    });
    res.curricular_box = array;

    await patchStepThreeCurriculumBoxData(res);
  };

  const assignQualificationId = (obj, e) => {
    if (!e.qualification_id) {
      obj.numeric_qualification = null;
      obj.qualification_id = null;
    } else if (isNaN(e.qualification_id) && e.qualification_id) {
      // eslint-disable-next-line no-unused-vars
      let prueba = tableInfo.options;
      obj.qualification_id = tableInfo.options.qualifications.filter((x) => x.name === e.qualification_id)[0].id;
    } else {
      obj.numeric_qualification = e.qualification_id;
    }
  };

  const handlePrevious = (level, setLevel, curriculumBox, edited) => {
    sendDataFromTable(level, setLevel, curriculumBox, null, edited);
    navigate(AnalyticRouteConstants.PASO2(params.id));
  };

  const getYearsArray = () => {
    let years = [];
    for (let i = new Date().getFullYear(); i >= 1900; i--) {
      years.push({ id: i, name: i.toString() });
    }
    return years;
  };

  return (
    <AnalyticalStepThreeContext.Provider
      value={{
        previewInfo,
        options: tableInfo.options,
        getStepThreeCurriculumBoxData,
        paginationProps,
        patchStepThreeCurriculumBoxData,
        sendDataFromTable,
        handlePrevious,
        updateInfoCurriculumBox,
        getStepThreeCurriculumBoxAllData,
        allLevelsCurriculum,
        validToFinish,
        analyticType,
        curriculumBox,
        setCurriculumBox,
        canLoadMaximumQualification
      }}>
      {children}
    </AnalyticalStepThreeContext.Provider>
  );
};
