import { Alert, Grid } from '@mui/material';

/**
 * Crea un Alert con un título y un texto del cuerpo.
 *
 * @param {string} title Título del Alert.
 * @param {string} bodyText Texto del cuerpo del Alert.
 * @param {string} severity Tipo de Severity del Alert. Puede ser "error", "warning", "info" o "success".
 * @returns Un JSX con el Alert.
 */
export const createAlert = (title, bodyText, severity, style) => {
  return (
    <Alert
      variant="outlined"
      severity={severity}
      sx={{ mt: 4, width: '100%', fontSize: 16, borderLeft: '8px solid #ff9800', mb: 2, ...style }}>
      {title && (
        <>
          <b>{title}</b>
          <br />
        </>
      )}
      {bodyText}
    </Alert>
  );
};

export const createAlertItem = (condition, message, key, style) => {
  return (
    condition && (
      <Grid item key={key}>
        {createAlert(null, message, 'warning', style)}
      </Grid>
    )
  );
};
