export const KEYS = {
  ENTER: 'Enter',
  ARROW_DOWN: 'ArrowDown',
  ARROW_UP: 'ArrowUp',
  ESCAPE: 'Escape',
  TAB: 'Tab',
  ESTABLISHMENT_TAB: 'EstablishmentTab',
  SHIFT: 'Shift',
  SHIFT_TAB: 'Shift_Tab',
  SHIFT_ENTER: 'Shift_Enter'
};

/**
 * Maneja eventos de navegación por teclado para una fila de tabla.
 *
 * @param {Object} event - El objeto de evento de teclado.
 * @param {Object} selectionStatusRef - Un objeto React ref para rastrear el estado de selección.
 * @param {Function} handleNavigation - Función de callback para manejar la navegación basada en la tecla presionada.
 * @param {string} tabKey - La tecla usada para manejar la navegación por tab.
 */
export const handleRowNavigation = (event, selectionStatusRef, handleNavigation, tabKey) => {
  const { ENTER, ARROW_DOWN, ARROW_UP, ESCAPE, TAB } = KEYS;
  switch (event.key) {
    case ENTER:
      if (selectionStatusRef.current === false) {
        handleNavigation(ENTER);
      }
      selectionStatusRef.current = false;
      break;
    case ARROW_DOWN:
    case ARROW_UP:
      selectionStatusRef.current = true;
      break;
    case ESCAPE:
      selectionStatusRef.current = false;
      break;
    case TAB:
      event.preventDefault();
      handleNavigation(tabKey);
      break;
  }
};

/**
 * Calcula el ID de una celda de la tabla para usar como key al crear su ref.
 * @param {*} rowId
 * @param {*} colIndex
 * @returns Id de la celda basada en el ID de las fila y columna a las que pertenece.
 */
export const calcRefId = (rowId, colIndex) => `${rowId}-${colIndex}`;

/**
 * Calcula el ID del siguiente celda para navegar basado en la tecla presionada.
 *
 * @param {number} rowId - El ID actual de la fila.
 * @param {number} colIndex - El índice actual de la columna.
 * @param {string} pressedKey - La tecla que se presionó para desencadenar la navegación.
 * @returns {string} El ID de la siguiente celda en formato 'rowId-colIndex'.
 *
 * La función devuelve el ID de la siguiente celda:
 * - Incrementando la fila si se la key es `Enter`.
 * - Moviéndose a la primera columna de la siguiente fila si se la key es `EstablishmentTab`.
 * - Incrementando la columna si se la key es `Tab`.
 * - Devolviendo el ID actual de la celda para cualquier otra tecla.
 */
export const calcNextCellId = (rowId, colIndex, pressedKey) => {
  const { ENTER, TAB, ESTABLISHMENT_TAB } = KEYS;
  switch (pressedKey) {
    case ENTER:
      return `${rowId + 1}-${colIndex}`;
    case ESTABLISHMENT_TAB:
      return `${rowId + 1}-0`;
    case TAB:
      return `${rowId}-${colIndex + 1}`;
    default:
      return `${rowId}-${colIndex}`;
  }
};
