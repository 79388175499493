import { Box, Typography } from '@mui/material';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { useMemo } from 'react';
import { steps } from '../tools/stepsAnalytical';
import { LEGALIZADO } from '../constants/AnalyticalStates';

/**
 * Obtiene la fecha de la última actualización para un estado específico del historial de cambios
 * @param {string|number} stepId - Identificador del paso/estado a buscar
 * @param {Array} changeHistory - Array de objetos que contiene el historial de cambios
 * @returns {string|undefined} Fecha de la última actualización en formato local o undefined si no existe
 */
const getStateLastUpdate = (stepId, changeHistory) => {
  if (!changeHistory) return;

  const history = changeHistory.find((history) => history.stateId === stepId);

  if (history?.last_update) {
    return new Date(history.last_update).toLocaleDateString();
  }
  return;
};

export const VerticalStepper = ({ state, stateHistory }) => {
  const activeStep = useMemo(() => {
    for (let i = 0; i < steps.length; i++) {
      if (steps[i].condition(state)) {
        if (state === LEGALIZADO) return i + 1;
        return i;
      }
    }
    return 0;
  }, [state]);

  return (
    <Box sx={{ maxWidth: 400, px: 2 }}>
      <Typography variant="body1">Seguimiento</Typography>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step) => {
          const last_update = getStateLastUpdate(step.id, stateHistory);
          return (
            <Step key={step.label}>
              <StepLabel>
                <Box alignItems={'start'}>
                  <Typography>{step.label}</Typography>
                  {last_update ? <Typography>{last_update}</Typography> : null}
                </Box>
              </StepLabel>
              <StepContent>
                <Typography>{step.description}</Typography>
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};
