import { Box } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { ShowDataDetail } from '../../../components/ShowDataDetail';
import { convertNumberQualification } from '../../../helpers/decimalHelper';
import { getUserPositions } from '../../../services/ProfileServices';

export const AnalyticalData = ({ student }) => {
  const [step2Info] = student.step2;
  const analytic_firms = student.firm && student.firm.length ? student.firm[0].analytic_firms : [];
  const [positions, setPositions] = useState([]);
  const [studentAverage, setStudentAverage] = useState('');

  const getUsersPositionsList = async () => {
    const response = await getUserPositions();
    setPositions(response || []);
  };

  const getPositionDescription = (positionId) => {
    const position = positions.find((p) => p.id === positionId);
    return position ? position.description : 'Posición no encontrada';
  };

  useEffect(() => {
    let qualificationList = student.step3
      .map((e) => e.numeric_qualification)
      .filter(function (el) {
        return el != null;
      });
    if (qualificationList.length > 0) {
      let qualificationSum = qualificationList.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
      let qualification = qualificationSum / qualificationList.length;
      setStudentAverage(convertNumberQualification(qualification));
    } else {
      setStudentAverage('');
    }
  }, [student]);

  useEffect(() => {
    getUsersPositionsList();
  }, []);

  return (
    <>
      {step2Info.broadcast_date && student.step1.study_plan && (
        <>
          <br />
          <Box>
            <ShowDataDetail
              title={'Plan de estudios'}
              info={`${student.step1.study_plan.study_plan_name} ${
                student.step1.study_plan.amending_resolution ? ` (${student.step1.study_plan.amending_resolution})` : ''
              }`}
              inline
            />
            <ShowDataDetail title={'Título'} info={student.step1.study_plan.title} inline />
            <ShowDataDetail title={'Validez Nacional otorgada por'} info={step2Info.national_validity_granted_by} inline />
            <ShowDataDetail title={'Copia'} info={step2Info.analytic_types.name} inline />
            {step2Info.analytic_types.id != 2 && <ShowDataDetail title={'Fecha de egreso'} info={step2Info.egress_date} inline />}
            <ShowDataDetail title={'Fecha de emisión'} info={moment(step2Info.broadcast_date).format('YYYY-MM-DD')} inline />
            <ShowDataDetail title={'Libro matríz Nro.'} info={step2Info.matrix_number} inline />
            <ShowDataDetail title={'Acta Nro.'} info={step2Info.act_number} inline />
            <ShowDataDetail title={'Folio Nro.'} info={step2Info.folio_number} inline />
            {step2Info.analytic_types.id != 2 && <ShowDataDetail title={'Promedio general'} info={studentAverage} inline />}
            <ShowDataDetail title={'Observaciones'} info={step2Info.observation} inline />
            {step2Info?.nation_code && <ShowDataDetail title={'N° de serie'} info={step2Info?.nation_code} inline />}
            {step2Info?.analytic_link && (
              <ShowDataDetail title={'Analítico digital'} info={step2Info?.analytic_link} isLink={true} inline />
            )}

            {analytic_firms &&
              analytic_firms.length !== 0 &&
              analytic_firms.map((signatories) => (
                <ShowDataDetail
                  title={'Firma'}
                  key={signatories.id}
                  info={`${signatories.user[0].user.first_name} ${signatories.user[0].user.last_name} CUIL: ${signatories.user[0].user.cuit} - ${getPositionDescription(signatories.user[0].user.position_id)}`}
                />
              ))}

            <br />
          </Box>
        </>
      )}
    </>
  );
};
